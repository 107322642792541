<template>
  <Tabs>
    <slot />
  </Tabs>
</template>

<script>
import Tabs from '@components/TUI/Tabs/Tabs'

export default {
  components: {
    Tabs
  }
}
</script>

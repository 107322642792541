<template>
  <g>
    <path d="M165.304 142.468L277.517 30.267c1.902-1.903 2.847-4.093 2.847-6.567 0-2.475-.951-4.665-2.847-6.567L263.239 2.857C261.337.955 259.146 0 256.676 0c-2.478 0-4.665.955-6.571 2.857L117.057 135.9c-1.903 1.903-2.853 4.093-2.853 6.567 0 2.475.95 4.664 2.853 6.567l133.048 133.043c1.903 1.906 4.086 2.851 6.564 2.851 2.478 0 4.66-.947 6.563-2.851l14.277-14.267c1.902-1.903 2.851-4.094 2.851-6.57 0-2.472-.948-4.661-2.851-6.564L165.304 142.468z"/><path
      v-if="double"
      d="M55.668 142.468L167.87 30.267c1.903-1.903 2.851-4.093 2.851-6.567 0-2.475-.947-4.665-2.851-6.567L153.6 2.857C151.697.955 149.507 0 147.036 0c-2.478 0-4.668.955-6.57 2.857L7.417 135.9c-1.903 1.903-2.853 4.093-2.853 6.567 0 2.475.95 4.664 2.853 6.567l133.048 133.043c1.902 1.906 4.09 2.851 6.57 2.851 2.471 0 4.661-.947 6.563-2.851l14.271-14.267c1.903-1.903 2.851-4.094 2.851-6.57 0-2.472-.947-4.661-2.851-6.564L55.668 142.468z"
    />
  </g>
</template>

<script>
export default {
  props: {
    double: {
      type: Boolean,
      default: false
    }
  }
}
</script>

import jobsApi from '@api/jobs'

const state = {
  jobs: null
}

const mutations = {
  /**
   * Commits jobs
   *
   * @param {Object} state
   * @param {Object} jobs
   */
  store(state, jobs) {
    state.jobs = jobs
  },

  /**
   * Add a job
   *
   * @param {Object} state
   * @param {Object} job
   */
  addJob(state, job) {
    state.jobs.push(job)
  }
}

const getters = {
  /**
   * Returns if jobs have loaded
   *
   * @param {Object} state
   * @return {string}
   */
  haveJobsLoaded: state => {
    return state.jobs !== null
  },

  /**
   * Returns all jobs
   *
   * @param {Object} state
   * @return {array}
   */
  jobs: state => {
    if (!state.jobs) {
      return
    }
    return state.jobs
  },

  /**
   * Returns number of jobs
   *
   * @param {Object} state
   * @return {Number}
   */
  jobsCount: state => {
    if (!state.jobs) {
      return
    }
    return state.jobs.length
  },

  /**
   * Returns jobs not archived
   *
   * @param {Object} state
   * @return {array}
   */
  activeJobs: (state, getters) => {
    if (!state.jobs) {
      return
    }
    return getters.jobs.filter(job => {
      return !job.closedAt
    })
  },

  /**
   * Returns jobs not archived
   *
   * @param {Object} state
   * @return {array}
   */
  archivedJobs: (state, getters) => {
    if (!state.jobs) {
      return
    }
    return getters.jobs.filter(job => {
      return !!job.closedAt
    })
  },

  /**
   * Returns open jobs
   *
   * @param {Object} state
   * @return {array}
   */
  openJobs: (state, getters) => {
    if (!state.jobs) {
      return
    }
    return getters.jobs.filter(job => {
      return !job.closedAt
    })
  }
}

const actions = {
  init({ dispatch }) {
    // dispatch('getJobs')
  },

  /**
   * Creates a job
   *
   * @param {Object} context
   * @param {Object} job
   * @return {Promise}
   */
  create({ commit, getters, dispatch }, job) {
    return jobsApi.create(job)
      .then(job => {
        dispatch('getJobs')
        return job
      })
  },

  /**
   * Gets jobs and adds to store
   *
   * @param {Object} context
   * @return {Promise}
   */
  getJobs({ commit }) {
    return jobsApi.index()
      .then(response => {
        commit('store', response.data)
      })
  },

  /**
   * Update a job
   *
   * @param {Object} context
   * @param {Object} job
   * @return {Promise}
   */
  update({ commit, getters, dispatch }, { id, job }) {
    return jobsApi.update(id, job)
      .then(job => {
        // @TODO 2019-12-05 For faster use, just update one job
        // commit('updateJob', job)
        // But for now just re-fetch all jobs as it’s easier
        return dispatch('getJobs')
      })
  },

  /**
   * Close a job
   *
   * @param {Object} context
   * @param {string} id
   * @return {Promise}
   */
  close({ commit, getters, dispatch }, id) {
    return jobsApi.close(id)
      .then(job => {
        return dispatch('getJobs')
      })
  },

  /**
   * Open a job
   *
   * @param {Object} context
   * @param {string} id
   * @return {Promise}
   */
  open({ commit, getters, dispatch }, id) {
    return jobsApi.open(id)
      .then(job => {
        return dispatch('getJobs')
      })
  }
}

export { state, mutations, getters, actions }

import { pickedApiRequest } from './request'

export default {
  /**
   * Create an employer
   *
   * @param {Object} employer
   * @return {Object}
   */
  create (employer) {
    return pickedApiRequest.post('assessment/employers', employer)
      .then(response => {
        return response.data
      })
  },

  /**
   * Show an employer
   *
   * @param {string} id
   * @return {Object}
   */
  show (id) {
    return pickedApiRequest.get('assessment/employers/' + id)
      .then(response => {
        return response.data
      })
  },

  /**
   * Update an employer
   *
   * @param {string} id
   * @param {Object} data
   * @return {Object}
   */
  update (id, data) {
    return pickedApiRequest.post('assessment/employers/' + id, data)
      .then(response => {
        return response.data
      })
  },

  /**
   * Request a magic link
   *
   * @param {string} email
   * @return {Object}
   */
  magicLink (email) {
    return pickedApiRequest.post('assessment/employers/magic-link', { email })
  },

  /**
   * Validate email address with code
   *
   * @param {string} validationCode
   * @return {Object}
   */
  validateEmail (validationCode) {
    return pickedApiRequest.post('assessment/employers/validate-email', {
      validationCode,
      payg: true
    })
      .then(response => {
        return response.data
      })
  },

  /**
   * Request an email verification link
   *
   * @param {string} validationCode
   * @return {Object}
   */
  emailValidationLink (employer) {
    return pickedApiRequest.post('assessment/employers/email-validation-link', employer)
      .then(response => {
        return response.data
      })
  },

  /**
   * Uses magic link to request a token
   *
   * @param {string} email
   * @return {Object}
   */
  token (id, token) {
    return pickedApiRequest.get('assessment/employers/' + id + '/token/' + token)
      .then(response => {
        return response.data
      })
  },

  /**
   * Create session via google sign-in
   *
   * @param {string} email
   * @return {Object}
   */
  createSessionViaGoogle(idToken) {
    return pickedApiRequest.post('assessment/employers/google', { idToken })
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Create session via google sign-in
   *
   * @param {string} email
   * @return {Object}
   */
  verifyGoogleEmail(idToken) {
    return pickedApiRequest.post('assessment/employers/google-email', { idToken })
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Gets notifications
   *
   * @param {string} id
   * @return {Object}
   */
  getNotifications (id) {
    return pickedApiRequest.get('assessment/employers/' + id + '/notifications')
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Posts notifications
   *
   * @param {string} id
   * @param {array} blockedNotifications
   * @return {Object}
   */
  postNotifications (id, blockedNotifications) {
    return pickedApiRequest.post('assessment/employers/' + id + '/notifications', { blockedNotifications })
      .then(response => {
        return response.data.data
      })
  }
}

<template>
  <PageBreadcrumbs :breadcrumbs="breadcrumbs">
  </PageBreadcrumbs>
</template>

<script>
import PageBreadcrumbs from '@components/TUI/PageHeading/PageBreadcrumbs'

export default {
  components: {
    PageBreadcrumbs
  },

  props: {
    breadcrumbs: {
      type: Array,
      default: () => []
    }
  }
}
</script>

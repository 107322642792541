<template>
  <Wrapper>
    <slot></slot>
  </Wrapper>
</template>

<script>
import Wrapper from '@components/TUI/Wrapper'

export default {
  components: {
    Wrapper
  }
}
</script>

<template>
  <h1
    v-if="offlineConfirmed"
  >
    The page timed out while loading. Are you sure you're still connected to
    the Internet?
  </h1>
  <LoadingView v-else />
</template>

<script>
// import axios from "axios";
import LoadingView from './_loading.vue'

export default {
  page: {
    title: 'Page timeout',
    meta: [
      { name: 'description', content: 'The page timed out while loading.' }
    ]
  },

  components: { LoadingView },

  data() {
    return {
      offlineConfirmed: false
    }
  }
  // beforeCreate() {
  //   axios
  //     .head("/api/ping")
  //     .then(() => {
  //       window.location.reload();
  //     })
  //     .catch(() => {
  //       this.offlineConfirmed = true;
  //     });
  // }
}
</script>

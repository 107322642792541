<template>
  <TableLoader :cols="cols">
    <slot />
  </TableLoader>
</template>

<script>
import TableLoader from '@components/TUI/Tables/TableLoader'

export default {
  components: {
    TableLoader
  },

  props: {
    cols: {
      type: Number
    }
  }
}
</script>

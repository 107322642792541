import { pickedApiRequest } from './request'

export default {
  /**
   * Get list of banners
   *
   * @return {Array}
   */
  banners () {
    return pickedApiRequest.get('banners')
      .then(response => {
        return response.data.data
      })
  }
}

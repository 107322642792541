<template>
  <li class="block mr-8 last:mr-0">
    <router-link
      v-bind="$attrs"
      class="block py-4 text-gray-500 text-sm border-b-2 -mb-px-2"
      :class="{
        'border-secondary text-gray-800': active
      }"
    >
      <slot />
    </router-link>
  </li>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    }
  }
}
</script>

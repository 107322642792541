import { pickedApiRequest } from './request'

export default {
  /**
   * Gets a list of products
   *
   * @param {String} country
   * @param {String} currency
   * @return {Object}
   */
  index (country, currency) {
    return pickedApiRequest.get('products', {
      params: { country_code: country, currency_code: currency }
    })
      .then(response => {
        return response.data
      })
  }
}

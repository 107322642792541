<template>
  <div>
    <div class="fixed top-0 left-0 w-full z-50">
      <Banners @bannersActivated="bannersActivated = $event" />
      <Header />
    </div>
    <div :class="[bannersActivated ? 'pt-24' : 'pt-16']"></div>
  </div>
</template>

<script>
import Banners from '@components/Banners'
import Header from '@components/Header'

export default {
  components: {
    Banners,
    Header
  },

  data() {
    return {
      bannersActivated: false
    }
  }
}
</script>

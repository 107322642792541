<template>
  <div
    class="relative"
  >
    <button
      type="button"
      class="block focus:outline-none"
      @click="toggle"
      @focus="buttonHasFocus = true"
      @blur="buttonHasFocus = false"
    >
      <slot
        name="trigger"
        :hasFocus="buttonHasFocus"
        :isOpen="isOpen"
      />
    </button>

    <transition
      enter-active-class="transition-all duration-100 ease-out-quad"
      leave-active-class="transition-all duration-150 ease-in-quad"
      enter-class="opacity-0 transform scale-75"
      enter-to-class="opacity-100 transform scale-100"
      leave-class="opacity-100 transform scale-100"
      leave-to-class="opacity-0 transform scale-75"
    >
      <div
        v-show="isOpen"
      >
        <button
          type="button"
          class="z-30 block fixed inset-0 w-full h-full cursor-default"
          @click="isOpen = false"
        />
        <div class="absolute z-40 left-0">
          <slot name="dropdown-left" />
        </div>
        <div class="absolute z-40 right-0">
          <slot name="dropdown" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      buttonHasFocus: false,
      isOpen: false
    }
  },

  watch: {
    open(val) {
      this.isOpen = !this.isOpen
    },

    '$route' () {
      this.isOpen = false
    }
  },

  mounted() {
    const onEscape = (e) => {
      if (!this.isOpen || e.key !== 'Escape') {
        return
      }
      this.isOpen = false
    }

    document.addEventListener('keydown', onEscape)
    this.$on('hook:destroyed', () => {
      document.removeEventListener('keydown', onEscape)
    })
  },

  methods: {
    toggle() {
      this.isOpen = !this.isOpen
    }
  }
}
</script>

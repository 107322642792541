<template>
  <button
    class="uppercase inline-flex items-center space-x-1"
    @click="click()"
  >
    <span>
      <slot />
    </span>
    <Icon
      view-box="0 0 284 284"
      :class="['transform w-2 h-2', chevronDirection]"
    >
      <Chevron />
    </Icon>
  </button>
</template>

<script>
import Chevron from '@components/Icons/Chevron'
import Icon from '@components/Icons/Icon'

export default {
  components: {
    Chevron,
    Icon
  },

  props: {
    sortBy: {
      type: String,
      required: true
    },
    sortOrder: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },

  computed: {
    chevronDirection() {
      if (this.sortBy !== this.name) {
        // We’re not sorting by this column
        return 'rotate-270'
      }
      if (this.sortOrder === 'asc') {
        return 'rotate-90'
      } else if (this.sortOrder === 'desc') {
        return '-rotate-90'
      }

      console.log('Fallback column sort used')
      return 'rotate-270'
    }
  },

  methods: {
    click() {
      if (this.sortBy !== this.name) {
        // This sort is not the active sort by, so trigger the event to take
        // focus
        this.$emit('sortBy', this.name)
        return
      }

      // This is the active sort by, so just flip the order
      if (this.sortOrder === 'asc') {
        this.$emit('sortOrder', 'desc')
        return
      }
      this.$emit('sortOrder', 'asc')
    }
  }
}
</script>

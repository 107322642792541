import { pickedApiRequest } from './request'

export default {
  /**
   * Organisation’s subscriptions
   *
   * @param {string} organisationId
   * @return {Array}
   */
  index (organisationId) {
    return pickedApiRequest.get('assessment/organisations/' + organisationId + '/subscriptions')
      .then(response => {
        return response.data.data
      })
  },

  /**
   * @param {string} organisationId
   * @param {string} employerId
   * @param {string} paymentMethod
   * @param {string} country
   * @return {Promise}
   */
  createCustomer (organisationId, employerId, paymentMethod, country) {
    return pickedApiRequest.post('assessment/organisations/' + organisationId + '/subscriptions/customer', {
      employerId,
      paymentMethod,
      country
    })
  },

  /**
   * Create subscription
   *
   * @param {string} organisationId
   * @param {string} planSlug
   * @param {string} migration
   * @param {string} coupon
   * @param {string} country
   * @param {string} vatNumber
   * @return {Object}
   */
  create (organisationId, stripeId, migration, coupon, countryCode, vatNumber) {
    return pickedApiRequest.post('assessment/organisations/' + organisationId + '/subscriptions', {
      stripeId,
      migration,
      coupon,
      countryCode,
      vatNumber
    })
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Create trial subscription
   *
   * @param {string} organisationId
   * @param {string} planSlug
   * @return {Object}
   */
  createTrial (organisationId, planSlug) {
    return pickedApiRequest.post('assessment/organisations/' + organisationId + '/subscriptions/trial', {
      planSlug
    })
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Create subscription from option
   *
   * @param {string} organisationId
   * @param {string} planSlug
   * @return {Object}
   */
  createFromOption (organisationId, planSlug, ref) {
    return pickedApiRequest.post('assessment/organisations/' + organisationId + '/subscription-options', {
      planSlug,
      ref
    })
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Create subscription from option
   *
   * @param {string} organisationId
   * @param {string} ref
   * @return {Object}
   */
  confrimSubscriptionOption (organisationId, ref) {
    return pickedApiRequest.post('assessment/organisations/' + organisationId + '/subscription-options/confirm', {
      ref
    })
  },

  /**
   * Change subscription
   *
   * This uses the same endpoint as create, but different methods
   *
   * @param {string} organisationId
   * @param {string} stripeId
   * @param {string} subscriptionId
   * @param {string} migration
   * @param {string} coupon
   * @return {Promise}
   */
  change (organisationId, stripeId, subscriptionId, migration, coupon, countryCode, vatNumber) {
    return pickedApiRequest.post('assessment/organisations/' + organisationId + '/subscriptions', {
      stripeId, migration, subscriptionId, coupon, countryCode, vatNumber
    })
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Cancel subscription
   *
   * @param {string} organisationId
   * @return {Array}
   */
  cancelAtPeriodEnd (organisationId, subscriptionId) {
    return pickedApiRequest.post('assessment/organisations/' + organisationId + '/subscriptions/' + subscriptionId + '/cancel-at-period-end')
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Cancel subscription
   *
   * @return {Array}
   */
  cancelPaygSubscription () {
    return pickedApiRequest.post('assessment/payg-subscriptions/cancel')
      .then(response => {
        return response.data
      })
  },

  /**
   * Upgrade subscription
   *
   * @param {string} upgradePlanSlug
   * @return {Array}
   */
  upgradePaygSubscription (upgradePlanSlug) {
    return pickedApiRequest.post('assessment/payg-subscriptions/upgrade', {
      plan_slug: upgradePlanSlug
    })
      .then(response => {
        return response.data
      })
  },

  /**
   * Release a subscription from a schedule
   *
   * @param {string} organisationId
   * @param {string} subscriptionId
   * @return {Array}
   */
  releaseSchedule (organisationId, subscriptionId) {
    return pickedApiRequest.post('assessment/organisations/' + organisationId + '/subscriptions/release-schedule', {
      subscriptionId
    })
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Preview a subscription change
   *
   * @param {string} organisationId
   * @param {Object} data
   * @return {Array}
   */
  preview (organisationId, data) {
    return pickedApiRequest.post('assessment/organisations/' + organisationId + '/subscriptions/preview', data)
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Get an invoice
   *
   * @param {string} organisationId
   * @param {string} uuid
   * @return {Array}
   */
  invoice (organisationId, uuid) {
    return pickedApiRequest.get('assessment/organisations/' + organisationId + '/invoices/' + uuid)
      .then(response => {
        return response.data.data
      })
  }
}

<template>
  <THeadTH
    align="left"
    v-bind="$attrs"
  >
    <slot></slot>
  </THeadTH>
</template>

<script>
import THeadTH from '@components/TUI/Tables/THeadTH'

export default {
  components: {
    THeadTH
  }
}
</script>

// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#Testing_for_availability
const storageAvailable = type => {
  const storage = window[type]

  try {
    const x = '__storage_test__'
    storage.setItem(x, x)
    storage.removeItem(x)
    return true
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage.length !== 0
    )
  }
}

/**
 * Safely returns JSON if possible
 *
 * @param  {string} data
 * @return {string|object}
 */
const parseJsonSafely = data => {
  try {
    return JSON.parse(data)
  } catch (e) {
    return data
  }
}

/**
 * Get Item from LocalStorage
 *
 * @param  {string} keyName
 * @return {string}
 */
export const getLocalStorage = keyName => {
  if (!storageAvailable('localStorage')) {
    return
  }
  return parseJsonSafely(localStorage.getItem(keyName))
}

/**
 * Set Item in LocalStorage
 *
 * @param  {string} keyName
 * @param  {string} keyValue
 * @return void
 */
export const setLocalStorage = (keyName, keyValue) => {
  if (!storageAvailable('localStorage')) {
    console.warn('localStorage not available')
    return
  }

  if (typeof keyValue === 'object') {
    keyValue = JSON.stringify(keyValue)
  }

  localStorage.setItem(keyName, keyValue)
}

/**
 * Remove Item from LocalStorage
 *
 * @param  {string} keyName
 * @return {string}
 */
export const removeLocalStorage = keyName => {
  if (!storageAvailable('localStorage')) {
    return
  }
  return parseJsonSafely(localStorage.removeItem(keyName))
}

import axios from 'axios'

const pickedApiRequest = axios.create({
  baseURL: process.env.VUE_APP_PICKED_API_ENDPOINT
})

const pickedApiCandidateRequest = axios.create({
  baseURL: process.env.VUE_APP_PICKED_API_ENDPOINT
})

pickedApiRequest.interceptors.request.use((config) => {
  if (localStorage.getItem('employer.token')) {
    const bearer = 'Bearer ' + localStorage.getItem('employer.token')
    config['headers']['Authorization'] = bearer
  }
  if (localStorage.getItem('employer.ghost')) {
    config['headers']['X-Ghost-User'] = localStorage.getItem('employer.ghost')
  }
  if (localStorage.getItem('employer.childOrganisationId')) {
    config['headers']['X-Organisation-ID'] = localStorage.getItem('employer.childOrganisationId')
  }
  return config
})

pickedApiCandidateRequest.interceptors.request.use((config) => {
  if (localStorage.getItem('candidateWizard.uuid')) {
    config['auth'] = {
      username: localStorage.getItem('candidateWizard.uuid'),
      password: localStorage.getItem('candidateWizard.password')
    }
  }
  return config
})

export { pickedApiRequest, pickedApiCandidateRequest }

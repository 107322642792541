<template>
  <table
    class="w-full text-gray-800 border-collapse"
    :class="fixed ? 'table-fixed' : ''"
  >
    <slot />
  </table>
</template>

<script>
export default {
  props: {
    fixed: {
      type: Boolean,
      default: false
    }
  }
}
</script>

import featuresConfig from '@src/../../resources/features.json'

const FeaturesService = {
  /**
   * Install the plugin
   *
   * @param {Object} vue
   * @param {Object} managerOptions Options from the config
   */
  install(Vue, managerOptions) {
    if (!process.env.VUE_APP_SATELLITE) {
      console.error('VUE_APP_SATELLITE is missing')
      return
    }

    Vue.prototype.$usesFeature = (feature) => {
      const features = featuresConfig.app[process.env.VUE_APP_SATELLITE]
      return features.includes(feature)
    }
  }
}

export default FeaturesService

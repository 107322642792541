var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.banner)?_c('div',{staticClass:"relative bg-secondary"},[_c('div',{staticClass:"px-3 py-3 mx-auto max-w-7xl sm:px-6 lg:px-8"},[_c('div',{staticClass:"pr-16 text-sm sm:text-center sm:px-16"},[_c('p',{staticClass:"font-medium text-white"},_vm._l((_vm.banner.message),function(segment,index){return _c('span',{key:index},[(segment.type === 'text')?_c('span',[_vm._v(" "+_vm._s(segment.data.label)+" ")]):(segment.type === 'discountCode' && !_vm.isPAYG)?_c('span',[(_vm.$route.name !== 'settings-plan')?_c('router-link',{staticClass:"font-bold text-white underline hover:text-gray-300",attrs:{"to":{
                name: 'settings-plan',
                query: {
                  code: segment.data.code
                }
              }}},[_vm._v(" "+_vm._s(segment.data.label)+" ")]):(_vm.$route.name === 'settings-plan')?_c('span',{staticClass:"font-bold text-white"},[_vm._v(" "+_vm._s(segment.data.label)+" ")]):_vm._e()],1):(segment.type === 'discountCode' && _vm.isPAYG)?_c('span',[(_vm.$route.name !== 'settings-payg-plan')?_c('router-link',{staticClass:"font-bold text-white underline hover:text-gray-300",attrs:{"to":{
                name: 'settings-payg-plan',
                query: {
                  code: segment.data.code
                }
              }}},[_vm._v(" "+_vm._s(segment.data.label)+" ")]):(_vm.$route.name === 'settings-payg-plan')?_c('span',{staticClass:"font-bold text-white"},[_vm._v(" "+_vm._s(segment.data.label)+" ")]):_vm._e()],1):(segment.type === 'link')?_c('a',{staticClass:"font-bold text-white underline",attrs:{"href":segment.data.link}},[_vm._v(" "+_vm._s(segment.data.label)+" ")]):_vm._e()])}),0)])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
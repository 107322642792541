import { pickedApiCandidateRequest } from './request'
import axios from 'axios'

export default {
  /**
   * Get a job to register for
   *
   * @param {String} wizardSlug
   * @return {Object}
   */
  job (wizardSlug, invitation) {
    let path = 'assessment/wizard/jobs/' + wizardSlug
    if (invitation) {
      path = 'assessment/wizard/jobs/' + wizardSlug + '/invitation/' + invitation
    }
    return pickedApiCandidateRequest.get(path)
      .then(response => {
        return response.data
      })
  },

  /**
   * Create Candidate Wizard Candidate
   *
   * @param {Object} candidate
   * @return {Object}
   */
  create (candidate) {
    return pickedApiCandidateRequest.post('assessment/wizard/candidate', candidate)
      .then(response => {
        return response.data
      })
  },

  /**
   * Candidate SignIn Via Google
   *
   * @param {Object} candidate
   * @return {Object}
   */
  googleSignUp (candidate) {
    return pickedApiCandidateRequest.post('assessment/wizard/candidate-google', candidate)
      .then(response => {
        return response.data
      })
  },

  /**
   * Candidate Sign in
   *
   * @param {string} email
   * @return {Object}
   */
  signIn (email, jobId) {
    return pickedApiCandidateRequest.post('assessment/wizard/candidate-sign-in', { email, jobId })
      .then(response => {
        return response.data
      })
  },

  /**
   * Uses magic link to request a token
   *
   * @param {string} email
   * @return {Object}
   */
  token (id, token) {
    return pickedApiCandidateRequest.get('assessment/wizard/candidates/' + id + '/token/' + token)
      .then(response => {
        return response.data
      })
  },

  /**
   * Get Candidate Wizard Candidate
   *
   * @return {Object}
   */
  show () {
    return pickedApiCandidateRequest.get('assessment/wizard/candidate')
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Submit Candidate Profile
   *
   * @return {Object}
   */
  submitProfile (profile) {
    return pickedApiCandidateRequest.post('assessment/wizard/candidate-profile', profile)
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Get Candidate Profile
   *
   * @return {Object}
   */
  fetchProfile () {
    return pickedApiCandidateRequest.get('assessment/wizard/candidate-profile')
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Signs an image upload
   *
   * @return {Object}
   */
  getCloudinarySignature (data) {
    return pickedApiCandidateRequest.post('assessment/wizard/cloudinary-signature', data)
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Uploads a signed image to Cloudinary
   *
   * @return {Object}
   */
  uploadImage (data, cloudName) {
    return axios.post(`https://api.cloudinary.com/v1_1/${cloudName}/image/upload`, data)
      .then(response => {
        return response.data
      })
  },

  /**
   * Fetches questions for a custom exam
   *
   * @return {Object}
   */
  questions (examUuid) {
    return pickedApiCandidateRequest.get(`assessment/custom-exams/${examUuid}/questions`)
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Parses CV document
   *
   * @return {Object}
   */
  parseCV (data) {
    return pickedApiCandidateRequest.post(`assessment/wizard/parse-cv`, data)
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Submits answers to Personal questions
   *
   * @return {Object}
   */
  personalAnswers (data) {
    return pickedApiCandidateRequest.post(`assessment/wizard/personal-answers`, data)
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Gets list of job roles
   *
   * @param {String} query
   * @return {Object}
   */
  searchJobRoles (query) {
    return pickedApiCandidateRequest.get(`assessment/wizard/job-roles`, {
      params: {
        q: query
      }
    })
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Gets a job role
   *
   * @param {String} jobSlug
   * @return {Object}
   */
  fetchJobRole (jobSlug) {
    return pickedApiCandidateRequest.get(`assessment/wizard/job-role`, {
      params: { slug: jobSlug }
    })
      .then(response => {
        return response.data.data
      })
  }
}

import employersApi from '@api/employers'
import { parseToken } from '@utils/jwt'

const state = {
  employer: null,
  token: null,
  ghost: false,
  authorisationAttempted: false
}

const mutations = {
  /**
   * Commits employer
   *
   * @param {Object} state
   * @param {Object}
   */
  employer(state, employer) {
    state.employer = employer
  },

  /**
   * Commits token
   *
   * @param {Object} state
   * @param {Object} token
   */
  token(state, token) {
    state.token = token
  },

  /**
   * Commits ghost status
   *
   * @param {Object} state
   * @param {Object} ghost
   */
  ghost(state, ghost) {
    state.ghost = ghost
  },

  /**
   * Commits authorisationAttempted status
   *
   * @param {Object} state
   * @param {Boolean} authorisationAttempted
   */
  authorisationAttempted(state, authorisationAttempted) {
    state.authorisationAttempted = authorisationAttempted
  }
}

const getters = {
  /**
   * Authorisation was attempted
   *
   * @param {Object} state
   * @return {Boolean}
   */
  authorisationAttempted: state => {
    return state.authorisationAttempted
  },

  /**
   * Is authorised
   *
   * @param {Object} state
   * @return {Boolean}
   */
  isAuthorised: state => {
    return !!state.employer
  },

  /**
   * Employer’s ID
   *
   * @param {Object} state
   * @return {string}
   */
  id: state => {
    if (!state.employer) {
      return
    }
    return state.employer.id
  },

  /**
   * Employer’s organisation’s ID
   *
   * @param {Object} state
   * @return {string}
   */
  organisationId: state => {
    if (!state.employer) {
      return
    }
    if (!state.employer.organisationId) {
      if (localStorage.getItem('employer.childOrganisationId')) {
        console.log('👨‍👦 Using child organisation')
        return localStorage.getItem('employer.childOrganisationId')
      }
    }
    return state.employer.organisationId
  },

  /**
   * Employer’s first name
   *
   * @param {Object} state
   * @return {string}
   */
  firstName: state => {
    if (!state.employer) {
      return
    }
    return state.employer.firstName
  },

  /**
   * Employer’s surname
   *
   * @param {Object} state
   * @return {string}
   */
  surname: state => {
    if (!state.employer) {
      return
    }
    return state.employer.surname
  },

  /**
   * Employer’s name (first and last)
   *
   * @param {Object} state
   * @return {string}
   */
  name: state => {
    if (!state.employer) {
      return
    }
    return `${state.employer.firstName} ${state.employer.surname}`
  },

  /**
   * Employer’s email
   *
   * @param {Object} state
   * @return {string}
   */
  email: state => {
    if (!state.employer) {
      return
    }
    return state.employer.email
  },

  /**
   * Employer’s terms agreed date
   *
   * @param {Object} state
   * @return {string}
   */
  terms: state => {
    if (!state.employer) {
      return
    }
    return state.employer.terms
  },

  /**
   * @param {Object} state
   * @return {Array}
   */
  childOrganisations: state => {
    if (!state.employer) {
      return
    }
    return state.employer.childOrganisations
  },

  /**
   * @param {Object} state
   * @return {string}
   */
  childOrganisation: state => {
    if (!state.employer) {
      return
    }
    if (!state.employer.childOrganisations) {
      return
    }
    const childOrganisationId = localStorage.getItem('employer.childOrganisationId')
    if (!childOrganisationId) {
      return
    }

    return state.employer.childOrganisations.find(childOrganisation => {
      return childOrganisationId === childOrganisation.id
    })
  },

  /**
   * Ghost mode
   *
   * @param {Object} state
   * @return {Boolean}
   */
  ghost: state => {
    if (!state.ghost) {
      return
    }
    return state.ghost
  }
}

const actions = {
  // This is automatically run in `store/index.js` when the app
  // starts, along with any other actions named `init` in other modules.
  init({ state, dispatch }) {
    // Employer is loaded via the router
  },

  authorisationAttempted({ commit }) {
    commit('authorisationAttempted', true)
  },

  getEmployer({ commit, dispatch, state }) {
    return employersApi.show(state.token.uuid)
      .then(employer => {
        commit('employer', employer.data)
        dispatch('setChildOrganisationId')
      })
  },

  setChildOrganisationId({ state }, childOrganisationId) {
    if (!state.employer.childOrganisations) {
      console.log('🙋‍♂️ Employer has no children, removing item')
      localStorage.removeItem('employer.childOrganisationId')
      return
    }

    console.log('👨‍👦 Employer has children')

    if (childOrganisationId) {
      console.log('👨‍👦 Setting child organisation from option')
      localStorage.setItem('employer.childOrganisationId', childOrganisationId)
      return
    }

    if (localStorage.getItem('employer.childOrganisationId')) {
      console.log('👨‍👦 Child organisation is already set')
      return
    }

    let first = state.employer.childOrganisations[0]
    localStorage.setItem('employer.childOrganisationId', first.id)
    console.log('👨‍👦 No set child organisation, using', first.id)
  },

  /**
   * Creates a employer (with employer)
   *
   * @param {Object} context
   * @param {Object} employer
   * @return {Promise}
   */
  create({ dispatch }, employer) {
    return employersApi.create(employer)
      .then(employer => {
        console.log('👩‍💼 Created employer')
        // @TODO 2019-11-11 Replace with safe helper
        localStorage.setItem('employer.token', employer.token)
        return dispatch('signIn')
      })
  },

  /**
   * Sends link to validate email
   *
   * @param {Object} context
   * @param {Object} employer
   * @return {Promise}
   */
  emailValidationLink({ dispatch }, employer) {
    return employersApi.emailValidationLink(employer)
      .then(employer => {
        console.log('👩‍💼 Sent ')
        if (employer.token) {
          localStorage.setItem('employer.token', employer.token)
          return dispatch('signIn')
        }
      })
  },

  /**
   * @param {Object} context
   * @param {Object} employer
   * @return {Promise}
   */
  getTokenFromMagicLink({ commit, dispatch }, { id, token, ghost }) {
    return employersApi.token(id, token)
      .then(employer => {
        if (employer.token) {
          commit('ghost', ghost)
          localStorage.setItem('employer.ghost', 'admin')
          localStorage.setItem('employer.token', employer.token)
          return dispatch('signIn')
        }
      })
  },

  /**
   * @param {Object} context
   * @param {Object} employer
   * @return {Promise}
   */
  validateEmail({ commit, dispatch }, validationCode) {
    return employersApi.validateEmail(validationCode)
      .then(employer => {
        if (employer.token) {
          localStorage.setItem('employer.token', employer.token)
          localStorage.setItem('employer.telephone', employer.telephone)
          return dispatch('signIn')
        }
      })
  },

  /**
   * Sign in an employer
   *
   * @param {Object} context
   */
  signIn({ commit, dispatch }) {
    // @TODO 2019-11-11 Replace with safe helper
    const employerToken = localStorage.getItem('employer.token')

    if (!employerToken) {
      throw new Error('Token not in storage')
    }
    console.log('🔓 Token found, signing in')
    commit('token', parseToken(employerToken))
    return dispatch('getEmployer')
      .then(() => {
        dispatch('organisations/getOrganisation', null, { root: true })
        dispatch('subscriptions/getSubscriptions', null, { root: true })
      })
  },

  /**
   * Sign in an employer with google
   *
   * @param {Object} context
   */
  signInViaGoogle({ dispatch }, idToken) {
    return employersApi.createSessionViaGoogle(idToken)
      .then((employer) => {
        localStorage.setItem('employer.token', employer.token)
      })
  },

  /**
   * Sign in an employer with google
   *
   * @param {Object} context
   */
  verifyGoogleEmail({ dispatch }, idToken) {
    return employersApi.verifyGoogleEmail(idToken)
  },

  /**
   * Sign out an employer
   *
   * @param {Object} context
   */
  signOut({ commit, dispatch }) {
    // @TODO 2019-11-11 Replace with safe helper
    localStorage.removeItem('employer.token')
    localStorage.removeItem('employer.childOrganisationId')
  },

  /**
   * @param {Object} context
   * @param {Object} employer
   * @return {Promise}
   */
  update({ commit, getters }, employer) {
    return employersApi.update(getters.id, employer)
      .then(employer => {
        console.log('😎 Received updated employer data')
        commit('employer', employer.data)
      })
  }
}

export { state, mutations, getters, actions }

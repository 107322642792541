<template>
  <BaseWrapper>
    <ErrorBlock />
  </BaseWrapper>
</template>

<script>
import ErrorBlock from '@components/TUI/ErrorBlock'

export default {
  components: {
    ErrorBlock
  }
}
</script>

import App from './App.vue'
import router from '@router'
import store from '@store'
import Vue from 'vue'
import VueGtm from 'vue-gtm'
import FeaturesService from '@plugins/FeaturesService'
import VueTour from 'vue-tour'
import InstantSearch from 'vue-instantsearch'
import VueObserveVisibility from 'vue-observe-visibility'

import '@components/_globals'

import PortalVue from 'portal-vue'
import GSignInButton from 'vue-google-signin-button'

import * as Integrations from '@sentry/integrations'
import * as Sentry from '@sentry/browser'

Vue.use(PortalVue)
Vue.use(FeaturesService)
Vue.use(VueTour)
Vue.use(InstantSearch)
Vue.use(GSignInButton)
Vue.use(VueObserveVisibility)

if (process.env.VUE_APP_GOOGLE_CONTAINER_ID) {
  Vue.use(VueGtm, {
    id: process.env.VUE_APP_GOOGLE_CONTAINER_ID,
    vueRouter: router
  })
}

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
    integrations: [new Integrations.Vue({ Vue, attachProps: true })]
  })
}

// Capitalize
// @TODO - move to separate file
Vue.filter('capitalize', value => {
  if (!value && value !== 0) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

<template>
  <div class="w-full relative mx-auto flex flex-col justify-center items-center p-6 h-screen">
    <Empty class="max-w-lg" />
    <h1 class="text-center">
      <span class="font-semibold block text-6xl">404</span>
      <template v-if="resource">
        {{ resource }}
      </template>
      Not Found
    </h1>
    <BaseButton
      :to="{ name: 'home' }"
      class="mt-6"
    >
      Back to home
    </BaseButton>
  </div>
</template>

<script>
import Empty from '@components/Vectors/Empty.vue'

export default {
  page: {
    title: '404',
    meta: [{ name: 'description', content: '404' }]
  },

  components: { Empty },

  props: {
    resource: {
      type: String,
      default: ''
    }
  },

  created() {
    throw new Error('Cannot find ' + this.$route.redirectedFrom)
  }
}
</script>

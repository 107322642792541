<template>
  <div>
    <!-- <BaseButton @click="modalOpen = true"> -->
    <BaseButton :href="`mailto:${supportEmail}`">
      <template slot="iconLeft">
        <Icon
          width="12px"
          height="12px"
          view-box="0 0 14 14"
          class="mr-4"
        >
          <Plus />
        </Icon>
      </template>
      Email support
    </BaseButton>

    <!-- @TODO 2019-12-09 Replace with integration to FreshDesk -->
    <!-- <Modal
      :open="modalOpen"
      @close="modalOpen = false"
    >
      <div class="border-b-2 bg-white">
        <div class="flex justify-between px-6 pb-6">
          <h3 class="font-medium text-lg">
            Submit request
          </h3>
          <button
            class="text-gray-500 focus:outline-none"
            @click="modalOpen = false"
          >
            <Icon
              width="16px"
              height="16px"
              view-box="0 0 14 14"
              class="rotate-45"
            >
              <Plus />
            </Icon>
          </button>
        </div>
      </div>

      <SupportRequestForm @close="modalOpen = false" />
    </Modal> -->
  </div>
</template>

<script>
// import Modal from '@components/Modal'
// import SupportRequestForm from '@components/SupportRequestForm'
import Icon from '@components/Icons/Icon'
import Plus from '@components/Icons/Plus'

export default {
  components: {
    // Modal,
    // SupportRequestForm,
    Icon,
    Plus
  },

  data() {
    return {
      modalOpen: false,
      supportEmail: process.env.VUE_APP_SUPPORT_EMAIL
    }
  }
}
</script>

import { pickedApiRequest } from './request'

export default {
  /**
   * Validate a token
   *
   * @param {string} token
   * @param {Object} data (candiateId, jobId)
   * @return {Object}
   */
  validate (token, data) {
    return pickedApiRequest.post('assessment/candidate-tokens/' + token, data)
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Record an exam started
   *
   * @param {string} token
   * @param {Object} data
   * @param {Object} data.examSlug
   * @return {Object}
   */
  recordExamStarted (token, data) {
    return pickedApiRequest.post('assessment/candidate-tokens/' + token + '/exam-started', data)
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Request a magic link
   *
   * @param {string} email
   * @return {Object}
   */
  candidateReminder (email) {
    return pickedApiRequest.post('assessment/candidate-reminder', { email })
  },

  /**
   * @param {string} jobUuid
   * @return {Object}
   */
  openSignUpJob (jobUuid) {
    return pickedApiRequest.get('assessment/open-sign-up/job/' + jobUuid)
      .then(response => {
        return response.data.data
      })
  },

  /**
   * @param {string} jobUuid
   * @param {Ojbect} data
   * @return {Object}
   */
  openSignUpCandidate (jobUuid, data) {
    return pickedApiRequest.post('assessment/open-sign-up/candidate/' + jobUuid, data)
      .then(response => {
        return response.data.data
      })
  }
}

import provisionalCandidatesApi from '@api/provisionalCandidates'

const state = {
  token: null,
  group: null,
  exams: null,
  attempts: [],
  organisation: null
}

const getters = {
  /**
   * @param {Object} context
   * @return {string}
  */
  token: state => {
    return state.token
  },

  /**
   * @param {Object} context
   * @return {Object}
  */
  group: state => {
    return state.group
  },

  /**
   * @param {Object} context
   * @return {Array}
  */
  exams: state => {
    if (!state.exams) {
      return []
    }
    return state.exams
  },

  /**
   * @param {Object} context
   * @return {Object}
  */
  organisation: state => {
    return state.organisation
  },

  /**
   * @param {Object} context
   * @return {Array}
  */
  attempts: state => {
    if (!state.attempts) {
      return []
    }
    return state.attempts
  }
}

const mutations = {
  /**
   * @param {Object} state
   * @param {Object} data
   */
  setTokenData(state, data) {
    state.token = data.token
    state.exams = data.exams
    state.attempts = data.attempts
    state.organisation = data.organisation
    state.group = data.group
  },

  /**
   * Commits validation
   *
   * @param {Object} attempt
   */
  addAttempt(state, attempt) {
    state.attempts.push(attempt)
  }
}

const actions = {
  /**
   * Validate incoming group and token
   *
   * @param {Object} context
   * @param {Object} payload
   * @param {string} payload.group
   * @param {string} payload.token
   * @return {Promise}
   */
  validate({ commit }, { group, token }) {
    return provisionalCandidatesApi.validate(group, token)
      .then(data => {
        commit('setTokenData', data)
      })
  }
}

export { state, mutations, getters, actions }

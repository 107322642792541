import { pickedApiRequest } from './request'

export default {
  /**
   * Get custom exams
   *
   * @param {string} organisationId
   * @return {Array}
   */
  exams (organisationId) {
    return pickedApiRequest.get('assessment/organisations/' + organisationId + '/exams')
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Get custom exam
   *
   * @param {string} organisationId
   * @param {string} examId
   * @return {Array}
   */
  showExam (organisationId, examId) {
    return pickedApiRequest.get('assessment/organisations/' + organisationId + '/exams/' + examId)
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Create custom exam
   *
   * @param {string} organisationId
   * @param {Object} exam
   * @return {Array}
   */
  createExam (organisationId, exam) {
    return pickedApiRequest.post('assessment/organisations/' + organisationId + '/exams', exam)
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Update custom exam
   *
   * @param {string} organisationId
   * @param {string} examId
   * @param {Object} exam
   * @return {Array}
   */
  updateExam (organisationId, examId, exam) {
    return pickedApiRequest.post('assessment/organisations/' + organisationId + '/exams/' + examId, exam)
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Archive/Softdelete custom exam
   *
   * @param {string} organisationId
   * @param {string} examId
   * @return {Array}
   */
  archiveExam (organisationId, examId) {
    return pickedApiRequest.post('assessment/organisations/' + organisationId + '/exams/' + examId + '/archive')
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Get questions for custom exam
   *
   * @param {string} organisationId
   * @param {string} examId
   * @return {Array}
   */
  fetchQuestions (organisationId, examId) {
    return pickedApiRequest.get('assessment/organisations/' + organisationId + '/exams/' + examId + '/questions')
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Update questions for custom exam
   *
   * @param {string} organisationId
   * @param {string} examId
   * @param {Object} questions
   * @return {Array}
   */
  updateQuestions (organisationId, examId, questions) {
    return pickedApiRequest.post('assessment/organisations/' + organisationId + '/exams/' + examId + '/questions', questions)
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Delete a question from custom exam
   *
   * @param {string} organisationId
   * @param {string} examId
   * @param {string} questionId
   * @return {Object}
   */
  deleteQuestion (organisationId, examId, questionId) {
    return pickedApiRequest.post('assessment/organisations/' + organisationId + '/exams/' + examId + '/questions/' + questionId + '/delete')
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Signs an image upload
   *
   * @return {Object}
   */
  getCloudinarySignature (data) {
    return pickedApiRequest.post('assessment/cloudinary-signature', data)
      .then(response => {
        return response.data.data
      })
  }
}

import examsApi from '@api/exams'

const state = {
  result: {}
}

const mutations = {
  setResults(state, result) {
    state.result = result
  }
}

const actions = {
  /**
   * Submit Drives result to API
   *
   * @param {Object} context
   * @return {Promise}
   */
  endDrivesExam({ state, commit, rootGetters }, { exam, jobUuid, antiCheatData }) {
    console.log('📒 Submitting Drives exam result')

    const result = {
      candidateId: rootGetters['candidates/candidateId'],
      examSlug: 'drives',
      jobId: rootGetters['candidates/jobId'],
      candidateSittingToken: rootGetters['candidates/candidateSittingToken'],
      scores: state.result
    }

    const endPoint = (result) => {
      if (jobUuid) {
        return examsApi.candidateWizardScoresAttempt(
          { ...result,
            ...antiCheatData,
            jobId: jobUuid
          })
      }
      return examsApi.candidateScoresAttempt(
        {
          ...result,
          ...antiCheatData
        })
    }

    return endPoint(result)
      .then(response => {
        console.log('😎 Received submitted Drives exam result', response)
        commit('candidates/addAttempt', response, { root: true })
      })
  }
}
export { state, mutations, actions }

<template>
  <svg
    :viewBox="viewBox"
    :width="width"
    :height="height"
    :aria-labelledby="icon"
    role="presentation"
    :class="['inline-flex align-middle fill-current', classes]"
  >
    <!-- @TODO 2020-05-07 Evaluate if this is usful for a11y -->
    <!-- <title
      :id="icon"
      lang="en"
    >
      {{ icon }} icon
    </title> -->

    <slot />
  </svg>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: 'box'
    },
    viewBox: {
      type: String,
      default: '0 0 44 44'
    },
    width: {
      type: [Number, String],
      default: 44
    },
    height: {
      type: [Number, String],
      default: 44
    },
    classes: {
      type: String,
      default: ''
    }
  }
}
</script>

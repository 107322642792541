<template>
  <TBodyTD v-bind="$attrs">
    <slot></slot>
  </TBodyTD>
</template>

<script>
import TBodyTD from '@components/TUI/Tables/TBodyTD'

export default {
  components: {
    TBodyTD
  }
}
</script>

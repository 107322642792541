<template>
  <div>
    <div
      v-for="row in 3"
      :key="row"
      class="max-w-screen-xl mx-auto flex my-6"
    >
      <div
        v-for="index in cols"
        :key="`${row}-${index}`"
        :class="[colWidthClass, 'h-12 bg-gray-300 rounded animate-pulse mx-6']"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cols: {
      type: Number,
      default: 3
    }
  },

  computed: {
    /**
     * @return {string}
     */
    colWidthClass() {
      const widths = {
        2: 'w-1/2',
        3: 'w-1/3',
        4: 'w-1/4',
        5: 'w-1/5',
        6: 'w-1/6',
        7: 'w-1/7',
        8: 'w-1/8'
      }
      return widths[this.cols]
    }
  }
}
</script>

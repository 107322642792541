<template>
  <TBodyTH
    align="left"
    v-bind="$attrs"
  >
    <slot></slot>
  </TBodyTH>
</template>

<script>
import TBodyTH from '@components/TUI/Tables/TBodyTH'

export default {
  components: {
    TBodyTH
  }
}
</script>

<template>
  <div class="bg-white w-full py-15">
    <div class="px-20 ">
      <h2 class="text-3xl">
        Sorry, there was a problem
      </h2>
      <p class="text-gray-600 mb-6 text-xl">
        There was an issue loading this page
      </p>
    </div>
    <div class="grid grid-cols-2">
      <div class="px-20">
        <div class="mt-10 max-w-xs">
          <Empty />
        </div>
      </div>
      <div class="px-20">
        <p class="text-gray-600 mb-6 text-md">
          Refresh the page to try again. If the problem persists, contact our
          support team for help.
        </p>
        <SupportRequestButton />
      </div>
    </div>
  </div>
</template>

<script>
import Empty from '@components/Vectors/Empty'
import SupportRequestButton from '@components/SupportRequestButton'

export default {
  components: {
    Empty,
    SupportRequestButton
  }
}
</script>

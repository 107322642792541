<template>
  <Container>
    <slot></slot>
  </Container>
</template>

<script>
import Container from '@components/TUI/Container'

export default {
  components: {
    Container
  }
}
</script>

import Vue from 'vue'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

const requireComponent = require.context(
  // The relative path of the components folder
  '.',
  // Whether or not to look in subfolders
  false,
  // The regular expression used to match base component filenames
  /Base[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach(fileName => {
  // Get component config
  const componentConfig = requireComponent(fileName)

  // Get PascalCase name of component
  const componentName = upperFirst(
    camelCase(
      // Gets the file name regardless of folder depth
      fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, '')
    )
  )

  // Register component globally
  Vue.component(
    componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig
  )
})

/**
 * Exam Icons
 */

const examIcons = require.context(
  './Icons/Exams',
  false, // Whether or not to look in subfolders
  /\w+\.(vue|js)$/ // The regular expression used to match
)

examIcons.keys().forEach(fileName => {
  const componentConfig = examIcons(fileName)
  // const componentConfig = examIcons('Icons/Exams/' + fileName.split('/').pop())
  const componentName = upperFirst(camelCase(fileName.replace(/\.\w+$/, '')))

  Vue.component(
    'ExamIcon' + componentName,
    componentConfig.default || componentConfig
  )
})

import personalityData from '@data/personalityTest/personalityTest.json'
import personality202111Data from '@data/personalityTest/personalityTest202111.json'
import examplePersonalityData from '@data/exampleTests/personality/personality.json'

import examsApi from '@api/exams'
import provisionalCandidatesApi from '@api/provisionalCandidates'
import Vue from 'vue'

const PER_PAGE = 15

const state = {
  answers: {},
  statements: []
}

const getters = {
  /**
   * Have we loaded the statements?
   *
   * @param {Object} state
   * @return {array}
   */
  haveStatementsLoaded: (state) => (examSlug) => {
    return typeof state.statements[examSlug] !== 'undefined'
  },

  /**
   * Questions by page number
   *
   * @param {Number} pageNumber
   * @return {Array}
   */
  questionsByPage: (state, getters) => (pageNumber, personalityTraits, isExample, examSlug) => {
    // Make pageNumber 1 equal index 0, pageNumber 2 index 1, etc
    const pageIndex = pageNumber
    if (pageIndex === 0) {
      return getters.allQuestions(personalityTraits, examSlug, isExample)
        .slice(0, PER_PAGE)
    }
    return getters.allQuestions(personalityTraits, examSlug, isExample)
      .slice(pageIndex * PER_PAGE, pageIndex * PER_PAGE + PER_PAGE)
  },

  /**
   * Have all Personality test questions on a page been answered?
   *
   * @param {Object} context
   * @param {Object} data
   * @param {Number} data.pageNumber
   * @param {Array} data.personalityTraits
   * @return {Boolean}
   */
  answersOnPageComplete: (state, getters) => (pageNumber, personalityTraits, examSlug) => {
    if (Object.entries(state.answers).length >= getters.allQuestions(personalityTraits, examSlug).length) {
      // Allows the last page to have a number not equal to PER_PAGE, but the
      // candidate has answered all questions
      return true
    }
    return (Object.entries(state.answers).length >= ((pageNumber + 1) * PER_PAGE))
  },

  /**
   * @param {Object} state
   * @param {Array} personalityTraits
   * @return {Array}
   */
  allQuestions: state => (personalityTraits, examSlug, isExample) => {
    let personalityQuestions

    if (isExample) {
      personalityQuestions = examplePersonalityData
    } else if (examSlug === 'personality') {
      personalityQuestions = personalityData
    } else if (examSlug === 'personality-2021-11') {
      personalityQuestions = personality202111Data
    } else {
      personalityQuestions = state.statements[examSlug]
        ? state.statements[examSlug].map(statement => ({
          item: statement.reference,
          body: statement.question,
          reverse: statement.reversed
        }))
        : [] // Provide an empty array if state.statements[examSlug] is undefined
    }

    if (personalityTraits.length === 0) {
      return personalityQuestions
    }

    return personalityQuestions.filter(trait =>
      personalityTraits.includes(trait.item.replace(/[0-9]/g, ''))
    )
  },

  /**
   * @param {Object} context
   * @param {Array} personalityTraits
   * @return {Number}
   */
  totalPages: (state, getters) => (personalityTraits, examSlug) => {
    return Math.ceil(getters.allQuestions(personalityTraits, examSlug).length / PER_PAGE)
  }
}

const mutations = {
  /**
   * Commits statements
   *
   * @param {Object} state
   * @param {Object}
   */
  statements(state, { examSlug, statements }) {
    Vue.set(state.statements, examSlug, statements)
  },

  setAnswer(state, answer) {
    Vue.set(state.answers, answer.question, answer.answer)
  }
}

const actions = {
  /**
   * Gets statements and adds to store
   *
   * @param {Object} context
   * @return {Promise}
   */
  getStatements({ commit }, examSlug) {
    console.log('📒 Getting statements for', examSlug)
    return examsApi.statements(examSlug)
      .then(statements => {
        console.log('😎 Received statements data')
        commit('statements', { examSlug, statements })
      })
  },

  setAnswer({ commit }, answer) {
    commit('setAnswer', answer)
  },

  /**
   * Submit Personality answers to API
   *
   * @param {Object} context
   * @param {Object} exam
   * @return {Promise}
   */
  endPersonalityExam({ state, commit, rootGetters }, { exam, jobUuid, antiCheatData }) {
    console.log('📒 Submitting Personality exam result')

    if (rootGetters['provisionalCandidate/token']) {
      const result = {
        examSlug: exam.slug,
        scores: state.answers
      }

      return provisionalCandidatesApi.storeAttempt(
        rootGetters['provisionalCandidate/group'].slug,
        rootGetters['provisionalCandidate/token'],
        result
      )
        .then(response => {
          console.log('😎 Received submitted Personality exam result', response)
          commit('provisionalCandidate/addAttempt', response, { root: true })
        })
    }

    const result = {
      candidateId: rootGetters['candidates/candidateId'],
      examSlug: exam.slug,
      jobId: rootGetters['candidates/jobId'],
      scores: state.answers,
      candidateSittingToken: rootGetters['candidates/candidateSittingToken']
    }

    const endPoint = (result) => {
      if (jobUuid) {
        return examsApi.candidateWizardScoresAttempt(
          { ...result,
            ...antiCheatData,
            jobId: jobUuid
          })
      }
      return examsApi.candidateScoresAttempt(
        {
          ...result,
          ...antiCheatData
        })
    }

    return endPoint(result)
      .then(response => {
        console.log('😎 Received submitted Personality exam result', response)
        commit('candidates/addAttempt', response, { root: true })
      })
  }
}
export { state, getters, mutations, actions }

<template>
  <PageHeader>
    <slot />
  </PageHeader>
</template>

<script>
import PageHeader from '@components/TUI/PageHeading/PageHeader'

export default {
  components: {
    PageHeader
  }
}
</script>

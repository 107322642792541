<template>
  <div id="app">
    <div
      class="bg-gray-100 min-h-screen"
    >
      <transition
        v-if="!$route.meta.hideShell"
        appear
        enter-active-class="transition-all duration-500 ease-out"
        leave-active-class="transition-all duration-150 ease-in"
        enter-class="opacity-0"
        leave-class="opacity-100"
        enter-to-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <HeaderFixed v-if="$store.state.employers.authorisationAttempted" />
      </transition>

      <HeaderAssessments v-if="$route.meta.candidateWizard" />

      <RouterView :key="$route.fullPath"/>

      <portal-target name="modals"></portal-target>
      <portal-target name="slide-overs"></portal-target>
    </div>
  </div>
</template>

<script>
import HeaderFixed from '@components/HeaderFixed'
import HeaderAssessments from '@components/Assessments/AssessmentsHeader'

export default {
  components: {
    HeaderFixed,
    HeaderAssessments
  },

  beforeCreate() {
    // Used for App Loading screen
    window.APP_LOADED = true
  },

  page: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: process.env.VUE_APP_SATELLITE_NAME,
    // all titles will be injected into this template
    titleTemplate: `%s | ${process.env.VUE_APP_SATELLITE_NAME}`
  }
}
</script>

<style>
@import "~@assets/css/tailwind.css";
</style>

import { pickedApiRequest } from './request'

export default {
  /**
   * All plans
   *
   * @return {Array}
   */
  index () {
    return pickedApiRequest.get('assessment/plans')
      .then(response => {
        return response.data.data
      })
  },

  /**
   * All PAYG plans
   *
   * @return {Array}
   */
  paygIndex () {
    return pickedApiRequest.get('assessment/payg-plans')
      .then(response => {
        return response.data
      })
  }
}

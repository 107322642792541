/**
 * @param  {string} displayName
 * @return {string}
 */
export const nameToInitials = displayName => {
  if (!displayName) {
    return
  }

  let parts = displayName.split(/[\s-]+/)
  let initials = []

  for (let part in parts) {
    if (parts[part].length > 0) {
      initials.push(parts[part][0])
    }
  }

  if (initials.length === 0) {
    return 'X'
  }

  if (initials.length === 1) {
    initials = initials.join()
  }

  if (initials.length > 1) {
    initials = initials[0] + initials[initials.length - 1]
  }

  return initials.toUpperCase()
}

import { pickedApiRequest } from './request'
import axios from 'axios'

export default {
  /**
   * Get an organisation
   *
   * @param {string} id
   * @return {Object}
   */
  show (id) {
    return pickedApiRequest.get('assessment/organisations/' + id)
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Update an organisation
   *
   * @param {string} id
   * @return {Object}
   */
  update (id, data) {
    return pickedApiRequest.post('assessment/organisations/' + id, data)
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Get an organisation’s send exams template
   *
   * @param {string} id
   * @return {Object}
   */
  showSendExamsTemplate (id) {
    return pickedApiRequest.get('assessment/organisations/' + id + '/send-exams-template')
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Update an organisation’s send exams template
   *
   * @param {string} id
   * @param {object} data
   * @param {string} data.greeting
   * @param {string} data.introduction
   * @param {string} data.instructions
   * @param {string} data.signOff
   * @return {Object}
   */
  updateSendExamsTemplate (id, data) {
    return pickedApiRequest.post('assessment/organisations/' + id + '/send-exams-template', data)
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Update an organisation’s pipeline
   *
   * @param {Array} pipeline
   * @return {Array}
   */
  updatePipeline(id, pipeline) {
    return pickedApiRequest.post('assessment/organisations/' + id + '/pipeline-stage', pipeline)
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Delete an organisation’s pipeline stage
   *
   * @param {String} organisation
   * @param {Object} data
   * @return {Array}
   */
  deletePipelineStage (organisation, data) {
    return pickedApiRequest.post('assessment/organisations/' + organisation + '/pipeline-stage/delete', data)
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Move candidates to another pipeline stage
   *
   * @param {String} organisation
   * @param {String} job
   * @param {Object} data
   * @param {String} slug
   * @return {Array}
   */
  moveCandidatesToPipelineStage (organisation, job, data) {
    return pickedApiRequest.post('assessment/organisations/' + organisation + '/jobs/' + job + '/pipeline-candidate', data)
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Disqualify candidates from job
   *
   * @param {String} organisation
   * @param {String} job
   * @param {Array} candidates
   * @return {Array}
   */
  disqualifyCandidates (organisation, job, candidates) {
    return pickedApiRequest.post('assessment/organisations/' + organisation + '/jobs/' + job + '/pipeline-candidate/disqualify', candidates)
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Requalify candidates from job
   *
   * @param {String} organisation
   * @param {String} job
   * @param {Array} candidates
   * @return {Array}
   */
  requalifyCandidates (organisation, job, candidates) {
    return pickedApiRequest.post('assessment/organisations/' + organisation + '/jobs/' + job + '/pipeline-candidate/requalify', candidates)
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Update an organisation’s webhook endpoint
   *
   * @param {Object} data
   * @return {Object}
   */
  updateWebhookEndpoint(id, data) {
    return pickedApiRequest.post('assessment/organisations/' + id + '/webhook-endpoint', data)
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Update an organisation’s masked candidate attributes
   *
   * @param {Array} data
   * @return {Object}
   */
  maskedAttributes(id, data) {
    return pickedApiRequest.post('assessment/organisations/' + id + '/masked-attributes', { maskedAttributes: data })
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Uploads a signed image to Cloudinary
   *
   * @return {Object}
   */
  uploadLogo (data, cloudName) {
    return axios.post(`https://api.cloudinary.com/v1_1/${cloudName}/image/upload`, data)
      .then(response => {
        return response.data
      })
  },

  /**
   * Signs an image upload
   *
   * @return {Object}
   */
  getCloudinarySignature (data) {
    return pickedApiRequest.post('assessment/cloudinary-signature', data)
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Saves an uploaded logo
   *
   * @return {Object}
   */
  saveLogo (id, data) {
    return pickedApiRequest.post('assessment/organisations/' + id + '/save-logo', data)
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Saves a colour
   *
   * @return {Object}
   */
  saveColour (id, hexColour) {
    return pickedApiRequest.post('assessment/organisations/' + id + '/save-colour', {
      colour: hexColour
    })
      .then(response => {
        return response.data.data
      })
  }
}

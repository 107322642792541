<template>
  <PageActions>
    <slot />
  </PageActions>
</template>

<script>
import PageActions from '@components/TUI/PageHeading/PageActions'

export default {
  components: {
    PageActions
  }
}
</script>

import { pickedApiRequest } from './request'

export default {
  /**
   * Validate a token
   *
   * @param {string} group
   * @param {string} token
   * @return {Object}
   */
  validate (group, token) {
    return pickedApiRequest.get('assessment/provisional-candidates/' + group + '/' + token)
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Submit a an exam result
   *
   * @param {string} group
   * @param {string} token
   * @return {Object} attempt
   * @return {Object}
   */
  storeAttempt (group, token, attempt) {
    return pickedApiRequest.post('assessment/provisional-candidates/' + group + '/' + token + '/attempts', attempt)
      .then(response => {
        return response.data.data
      })
  }
}

<template>
  <PageHeading>
    <slot />
  </PageHeading>
</template>

<script>
import PageHeading from '@components/TUI/PageHeading/PageHeading'

export default {
  components: {
    PageHeading
  }
}
</script>

<template>
  <TBody>
    <slot></slot>
  </TBody>
</template>

<script>
import TBody from '@components/TUI/Tables/TBody'

export default {
  components: {
    TBody
  }
}
</script>

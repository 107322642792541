<template>
  <header>
    <div class="flex items-center">
      <span
        v-if="hasIcon"
        :class="['flex flex-col justify-center items-center h-10 w-10 rounded-full mr-4 text-white pt-1', bg]"
      >
        <Icon
          width="24px"
          height="24px"
          view-box="0 0 24 24"
        >
          <slot name="icon" />
        </Icon>
      </span>
      <h2 class="text-lg">
        <slot />
      </h2>
    </div>
  </header>
</template>

<script>
import Icon from '@components/Icons/Icon'

export default {
  components: {
    Icon
  },

  props: {
    bg: {
      type: String,
      default: 'bg-secondary'
    }
  },

  computed: {
    /**
     * @return {Boolean}
     */
    hasIcon() {
      return !!this.$slots.icon
    }
  }
}
</script>

<template>
  <THead>
    <slot></slot>
  </THead>
</template>

<script>
import THead from '@components/TUI/Tables/THead'

export default {
  components: {
    THead
  }
}
</script>

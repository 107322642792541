<template>
  <div :class="['dots-loader', color]">
    <div></div>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'text-secondary'
    }
  }
}
</script>

<style scoped>
.dots-loader {
  margin: 0 auto;
  width: 20px;
  text-align: center;
}

.dots-loader > div {
  background-color: currentColor;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  display: inline-block;
  animation: bouncedelay 1.4s infinite ease-in-out both;
}

@keyframes bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
</style>


<script>
export default {
  props: {
    noPadding: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    classes() {
      return ['bg-white p-6', { 'px-0 py-0': this.noPadding }]
    }
  },

  render(h) {
    return h(
      'div',
      {
        class: this.classes
      },
      this.$slots.default
    )
  }
}
</script>

<template>
  <SortBy
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </SortBy>
</template>

<script>
import SortBy from '@components/TUI/Tables/SortBy'

export default {
  components: {
    SortBy
  }
}
</script>

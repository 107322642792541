<template>
  <TabBar>
    <slot />
  </TabBar>
</template>

<script>
import TabBar from '@components/TUI/Tabs/TabBar'

export default {
  components: {
    TabBar
  }
}
</script>

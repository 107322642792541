<template>
  <Transition appear>
    Loading...
  </Transition>
</template>

<script>
export default {
  page: {
    title: 'Loading page...',
    meta: [{ name: 'description', content: 'Loading page...' }]
  }
}
</script>

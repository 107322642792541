import organisationsApi from '@api/organisations'
import { differenceInDays, isPast } from 'date-fns'

const state = {
  organisation: null
}

const mutations = {
  /**
   * Commits organisation
   *
   * @param {Object} state
   * @param {Object}
   */
  organisation(state, organisation) {
    state.organisation = organisation
  },

  /**
   * Commits pipeline
   *
   * @param {Object} state
   * @param {Array} pipeline
   */
  pipeline(state, pipeline) {
    state.organisation.pipeline = pipeline
  },

  /**
   * Commits webhook endpoint
   *
   * @param {Object} state
   * @param {string} webhookEndpoint
   */
  setWebhookEndpoint(state, webhookEndpoint) {
    state.organisation.webhookEndpoint = webhookEndpoint
  }
}

const getters = {
  /**
   * Employer’s first name
   *
   * @param {Object} state
   * @return {string}
   */
  hasOrganisationLoaded: state => {
    return state.organisation !== null
  },

  /**
   * @param {Object} state
   * @return {string}
   */
  name: state => {
    if (!state.organisation) {
      return
    }
    return state.organisation.name
  },

  /**
   * @param {Object} state
   * @return {string}
   */
  id: state => {
    if (!state.organisation) {
      return
    }
    return state.organisation.id
  },

  /**
   * @param {Object} state
   * @return {string}
   */
  domainName: state => {
    if (!state.organisation) {
      return
    }
    return state.organisation.domainName
  },

  /**
   * @param {Object} state
   * @return {string}
   */
  vatNumber: state => {
    if (!state.organisation) {
      return
    }
    return state.organisation.vatNumber
  },

  /**
   * @param {Object} state
   * @return {Object}
   */
  address: state => {
    if (!state.organisation) {
      return
    }
    return state.organisation.address
  },

  /**
   * @param {Object} state
   * @return {Boolean}
   */
  hasFreepass: state => {
    if (!state.organisation) {
      return
    }
    return state.organisation.freepass
  },

  /**
   * @param {Object} state
   * @return {Boolean}
   */
  isPAYG: state => {
    if (!state.organisation) {
      return
    }
    return state.organisation.isPAYG
  },

  /**
   * @param {Object} state
   * @return {Boolean}
   */
  attemptResultsLimit: state => {
    if (!state.organisation) {
      return
    }
    return state.organisation.tcAttemptResultsLimit
  },

  /**
   * @param {Object} state
   * @return {Boolean}
   */
  unlimitedResults: state => {
    if (!state.organisation) {
      return
    }
    return state.organisation.tcAttemptResultsSkip
  },

  /**
   * @param {Object} state
   * @return {Boolean}
   */
  hasUnlimitedJobs: state => {
    if (!state.organisation) {
      return false
    }
    return state.organisation.tcAttemptResultsSkip
  },

  /**
   * @param {Object} state
   * @return {Boolean}
   */
  hasPaygUnlockedExams: state => {
    if (!state.organisation) {
      return false
    }
    return state.organisation.paygUnlockedExams
  },

  /**
   * @param {Object} state
   * @return {Object}
   */
  completedAction: state => {
    if (!state.organisation) {
      return
    }
    return state.organisation.tcCompletedAction
  },

  /**
   * @param {Object} state
   * @return {Array}
   */
  provisionalGroups: state => {
    if (!state.organisation) {
      return []
    }
    return state.organisation.provisionalGroups
  },

  /**
   * @param {Object} state
   * @return {Boolean}
   */
  inTrial: state => {
    if (!state.organisation) {
      return false
    }
    return state.organisation.inTrial
  },

  /**
   * @param {Object} state
   * @return {String}
   */
  trialEndedAt: state => {
    if (!state.organisation) {
      return null
    }
    return state.organisation.trialEndedAt
  },

  /**
   * @param {Object} state
   * @return {String}
   */
  trialStartedAt: state => {
    if (!state.organisation) {
      return null
    }
    return state.organisation.trialStartedAt
  },

  /**
   * @return {Number}
   */
  trialDaysRemaining: state => {
    if (!state.organisation) {
      return null
    }
    if (!state.organisation.trialEndedAt) {
      return null
    }
    if (isPast(state.organisation.trialEndedAt)) {
      return null
    }
    return differenceInDays(new Date(state.organisation.trialEndedAt), new Date())
  },

  /**
   * @return {Number}
   */
  signedUpDaysAgo: (state, getters) => {
    if (!state.organisation) {
      return
    }
    return differenceInDays(new Date(), new Date(state.organisation.createdAt))
  },

  /**
   * @param {Object} state
   * @return {Array}
   */
  pipeline: state => {
    if (!state.organisation) {
      return []
    }
    return state.organisation.pipeline
  },

  /**
   * @param {Object} state
   * @return {Array}
   */
  editablePipeline: (state, getters) => {
    if (!state.organisation) {
      return []
    }

    const defaultStages = ['sourced', 'assessment', 'hired']
    const editableStages = getters.pipeline.filter(stage => !defaultStages.includes(stage.slug))

    // Reset Editable Stage positions (API wants to start at 0)
    return editableStages.map((stage, index) => {
      return {
        ...stage,
        position: index
      }
    })
  },

  /**
   * @param {Object} state
   * @return {string}
   */
  webhookEndpoint: state => {
    if (!state.organisation) {
      return null
    }
    return state.organisation.webhookEndpoint
  },

  /**
   * @param {Object} state
   * @return {Array}
   */
  exams: state => {
    if (!state.organisation) {
      return []
    }

    let exams = []

    if (Array.isArray(state.organisation.libraryExams)) {
      exams.push(...state.organisation.libraryExams)
    }

    if (Array.isArray(state.organisation.customExams)) {
      exams.push(...state.organisation.customExams)
    }

    return exams
  },

  /**
   * @param {Object} state
   * @return {Array}
   */
  assignableExams: state => {
    if (!state.organisation) {
      return []
    }

    return state.organisation.libraryExams.filter(exam => exam.isAssignable)
  },

  /**
   * Exam by exam slug
   *
   * @param {String} examSlug
   * @return {Object}
   */
  examBySlug: (state, getters) => examSlug => {
    if (!state.organisation) {
      return []
    }

    return getters.exams.find(exam => {
      return exam.slug === examSlug
    })
  },

  /**
   * @param {Object} state
   * @return {Array}
   */
  maskedAttributes: state => {
    if (!state.organisation) {
      return []
    }
    return state.organisation.maskedAttributes
  },

  /**
   * @param {Object} state
   * @return {Array}
   */
  specialFeatures: state => {
    if (!state.organisation) {
      return []
    }
    return state.organisation.specialFeatures
  },

  /**
   * @param {Object} state
   * @return {string}
   */
  paygUpgradeBeforeDate: state => {
    if (!state.organisation) {
      return null
    }
    return state.organisation.paygUpgradeBeforeDate
  },

  /**
   * @param {Object} state
   * @return {string}
   */
  brandingColour: state => {
    if (!state.organisation || !state.organisation.brandingColour) {
      return '#FFFFFF'
    }
    return state.organisation.brandingColour
  },

  /**
   * @param {Object} state
   * @return {string}
   */
  brandingLogoUrl: state => {
    if (!state.organisation) {
      return null
    }
    if (!state.organisation.brandingLogo) {
      return null
    }

    const logo = state.organisation.brandingLogo

    let url = []
    url.push('https://res.cloudinary.com')
    url.push(logo.cloudName)
    url.push('image/upload')
    url.push('h_40,w_auto')
    url.push('v' + logo.version)
    url.push(logo.publicId + '.' + logo.format)

    return url.join('/')
  }
}

const actions = {
  init({ dispatch }) {
    // dispatch('getOrganisation')
  },

  /**
   * Get the employer’s organisation
   */
  getOrganisation({ commit, rootGetters }) {
    const organisationId = rootGetters['employers/organisationId']
    if (!organisationId) {
      return
    }
    return organisationsApi.show(rootGetters['employers/organisationId'])
      .then(organisation => {
        if (organisation.specialFeatures.length > 0) {
          console.log('✨ Special features: ' + organisation.specialFeatures.join(', '))
        }
        if (organisation.isPAYG) {
          console.log('👏 Is PAYG')
        } else if (!organisation.isPAYG) {
          console.log('⏰ Is not PAYG')
        }
        commit('organisation', organisation)
      })
  },

  /**
   * Update an organisation
   *
   * @param {Object} context
   * @param {Object} organisation
   * @return {Promise}
   */
  updateOrganisation({ commit, rootGetters }, organisation) {
    return organisationsApi.update(rootGetters['employers/organisationId'], organisation)
      .then(organisation => {
        commit('organisation', organisation)
      })
  },

  /**
   * Update an organisation's pipeline
   *
   * @param {Object} context
   * @param {Array} pipeline
   * @return {Promise}
   */
  updatePipeline({ commit, rootGetters }, pipeline) {
    const editedPipeline = pipeline.map((stage, index) => ({ ...stage, position: index }))

    return organisationsApi
      .updatePipeline(rootGetters['employers/organisationId'], editedPipeline)
      .then(editedPipeline => {
        commit('pipeline', editedPipeline)
      })
  },

  /**
   * Delete pipeline
   *
   * @param {Array} pipeline
   * @return {Promise}
   */
  deletePipelineStage({ commit, rootGetters }, pipeline) {
    return organisationsApi.deletePipelineStage(rootGetters['employers/organisationId'], pipeline)
      .then(pipeline => {
        commit('pipeline', pipeline)
        // the first two stages and the last stages are static
        // so we only want to return the editable stages to the component
        return pipeline.slice(2, -1)
      })
  },

  /**
   * Update an webook endpoint
   *
   * @param {Object} context
   * @param {string} webhookEndpoint
   * @return {Promise}
   */
  updateWebhookEndpoint({ commit, rootGetters }, webhookEndpoint) {
    return organisationsApi.updateWebhookEndpoint(rootGetters['employers/organisationId'], webhookEndpoint)
      .then(organisation => {
        commit('organisation', organisation)
      })
  }
}

export { state, mutations, getters, actions }

<template>
  <component
    :is="component"
    :class="['focus:outline-none inline-flex items-center justify-center font-medium leading-tight duration-150 rounded', classes]"
    :style="styles"
    :disabled="disabled"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div
      v-if="hasIconLeftSlot && !loading"
      class="inline-flex mr-2 fill-current stroke-current"
    >
      <slot name="iconLeft" />
    </div>

    <div
      v-if="hasIconMiddleSlot && !loading"
      class="inline-flex fill-current stroke-current"
    >
      <slot name="iconMiddle" />
    </div>

    <span v-if="!loading">
      <slot />
    </span>

    <div
      v-if="hasIconRightSlot && !loading"
      class="inline-flex ml-2 fill-current stroke-current"
    >
      <slot name="iconRight" />
    </div>

    <span v-if="loading">
      <Loader
        v-if="!useSmallLoader"
        :color="loaderColor"
      />
      <LoaderSmall
        v-else-if="useSmallLoader"
        :color="loaderColor"
      />
    </span>
  </component>
</template>

<script>
import Loader from '@components/Loader'
import LoaderSmall from '@components/LoaderSmall'

import { isDark } from '@utils/colorContrast'

export default {
  components: {
    Loader,
    LoaderSmall
  },

  props: {
    variant: {
      type: String,
      default: 'green'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    useSmallLoader: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'standard'
    },
    customBackgroundColour: {
      type: String,
      default: null
    }
  },

  computed: {
    /**
     * @return {string}
     */
    component() {
      if (this.$attrs.to) {
        return 'router-link'
      } else if (this.$attrs.href) {
        return 'a'
      } else {
        return 'button'
      }
    },

    /**
     * @return {Array}
     */
    classes() {
      return [
        { 'bg-secondary hover:bg-secondary-400 text-white': this.variant === 'green' },
        { 'bg-white hover:bg-gray-200 text-gray-800': this.variant === 'white' },
        { 'bg-tertiary hover:bg-tertiary-400 text-gray-800': this.variant === 'salmon' },
        { 'bg-red-500 hover:bg-red-600 text-white': this.variant === 'danger' },
        { 'bg-gray-500 hover:bg-gray-600 text-white': this.variant === 'gray' },
        { 'bg-white hover:text-secondary-400 text-secondary border border-secondary shadow-none': this.variant === 'inverse' },
        { 'bg-transparent hover:text-gray-700 border border-gray-500 shadow-none': this.variant === 'ghost' },
        { 'shadow-none': this.variant === 'custom' },
        { 'bg-transparent shadow-none': this.loading },
        { 'opacity-50 pointer-events-none': this.disabled },

        { 'px-4 py-3': this.size === 'standard' },
        { 'px-6 py-3 text-lg': this.size === 'large' },
        { 'px-4 py-2': this.size === 'small' }
      ]
    },

    styles() {
      if (!this.customBackgroundColour || this.variant !== 'custom') {
        return null
      }

      let styles = []

      let backgroundColour = this.customBackgroundColour
      let foregroundColour = '#FFFFFF'

      styles.push(`background-color: ${backgroundColour};`)

      if (isDark(backgroundColour)) {
        foregroundColour = '#000000'
      }
      styles.push(`color: ${foregroundColour};`)

      return styles.join(' ')
    },

    /**
     * @return {String}
     */
    loaderColor() {
      return this.variant === 'green' ? 'text-white' : 'text-gray-800'
    },

    /**
     * @return {Boolean}
     */
    hasIconLeftSlot() {
      return !!this.$slots.iconLeft
    },

    /**
     * @return {Boolean}
     */
    hasIconMiddleSlot() {
      return !!this.$slots.iconMiddle
    },

    /**
     * @return {Boolean}
     */
    hasIconRightSlot() {
      return !!this.$slots.iconRight
    }
  }
}
</script>

<template>
  <Tab v-bind="$attrs">
    <slot />
  </Tab>
</template>

<script>
import Tab from '@components/TUI/Tabs/Tab'

export default {
  components: {
    Tab
  }
}
</script>

import subscriptionsApi from '@api/subscriptions'
import { isFuture, parseISO } from 'date-fns'

const activeStatuses = [
  'active'
  // @NB We added `past_due` because a few people pay by invoice, but we
  // allow them to still use the site because they are probably going to pay.
  // However, this causes issues for invoices that are unpaid due to a card,
  // so this may have to change back
  // 'past_due'
]

const state = {
  subscriptions: null,
  subscription: null
}

const mutations = {
  /**
   * Commits subscriptions
   *
   * @param {Object} state
   * @param {Object}
   */
  subscriptions(state, subscriptions) {
    state.subscriptions = subscriptions
  },

  /**
   * Commits subscription
   *
   * @param {Object} state
   * @param {Object}
   */
  subscription(state, subscription) {
    state.subscription = subscription
  }
}

const getters = {
  /**
   * Returns if subscriptions have loaded
   *
   * @param {Object} state
   * @return {Boolean}
   */
  haveSubscriptionsLoaded: state => {
    return state.subscriptions !== null
  },

  /**
   * Returns if subscription has loaded
   *
   * @param {Object} state
   * @return {Boolean}
   */
  hasSubscriptionLoaded: state => {
    return state.subscription !== null
  },

  /**
   * @return {Object}
   */
  currentSubscription: (state, getters, rootState, rootGetters) => {
    if (getters.activeSubscriptions.length === 0) {
      return
    }
    if (getters.activeSubscriptions.length > 1) {
      // This is just a warning to flag up for the future if we end up with
      // organisations with multiple plans
      console.error('More than one subscription found')
    }

    return getters.activeSubscriptions.find(subscription => {
      if (isFuture(parseISO(subscription.currentPeriodStart))) {
        // This subscription has not yet started
        return false
      }
      return true
    })
  },

  /**
   * Returns if subscription has loaded
   *
   * @param {Object} state
   * @return {Object}
   */
  paygSubscription: state => {
    return state.subscription
  },

  /**
   * Returns if subscription has loaded
   *
   * @param {Object} state
   * @return {Object}
   */
  hasAnySubscription: (state, getters) => {
    if (state.subscription) {
      return true
    }
    if (getters.activeSubscriptions.length > 0) {
      return true
    }
    return false
  },

  /**
   * Returns active subscriptions for organisation
   *
   * @param {Object} state
   * @return {Array}
   */
  activeSubscriptions: state => {
    if (!state.subscriptions) {
      return []
    }
    return state.subscriptions.filter(subscription => {
      return activeStatuses.includes(subscription.status)
    })
  },

  /**
   * Returns trial subscriptions
   *
   * @param {Object} state
   * @return {Array}
   */
  trialSubscriptions: state => {
    if (!state.subscriptions) {
      return []
    }
    return state.subscriptions.filter(subscription => {
      return subscription.status === 'trialing'
    })
  },

  /**
   * Returns inactive subscriptions for organisation
   *
   * @param {Object} state
   * @return {Array}
   */
  inactiveSubscriptions: state => {
    if (!state.subscriptions) {
      return []
    }
    return state.subscriptions.filter(subscription => {
      return !activeStatuses.includes(subscription.status)
    })
  },

  /**
   * Returns cancelled subscriptions for an organisation
   *
   * @param {Object} state
   * @return {Array}
   */
  cancelledSubscriptions: state => {
    if (!state.subscriptions) {
      return []
    }
    return state.subscriptions.filter(subscription => {
      return subscription.status === 'canceled'
    })
  },

  /**
   * Has active subscription?
   *
   * @param {Object} state
   * @return {Boolean}
   */
  hasActiveSubscription: (state, getters, rootState, rootGetters) => {
    if (!state.subscriptions) {
      return false
    }
    if (rootGetters['organisations/unlimitedResults']) {
      return true
    }
    if (getters.trialSubscriptions.length > 0) {
      // Trial subscriptions count as ‘active’
      return true
    }
    return !!getters.activeSubscriptions.length
  },

  /**
   * Is one of the plans pending?
   *
   * @param {Object} state
   * @return {Boolean}
   */
  hasPendingSubscription: (state, getters) => {
    if (!state.subscriptions) {
      return false
    }
    return !!getters.activeSubscriptions.find(subscription => {
      return subscription.schedule.length > 0
    })
  },

  /**
   * @param {Object} state
   * @return {Boolean}
   */
  hasCancelledSubscription: (state, getters) => {
    if (!state.subscriptions) {
      return false
    }
    return getters.cancelledSubscriptions.length > 0
  },

  /**
   * Is one of the plans in a trial?
   *
   * @param {Object} state
   * @return {Boolean}
   */
  hasTrialSubscription: (state, getters) => {
    if (!state.subscriptions) {
      return false
    }
    return !!getters.trialSubscriptions.find(subscription => {
      return subscription.schedule.length > 0
    })
  },

  /**
   * Is there a reactivatable subscription?
   *
   * @param {Object} state
   * @return {Boolean}
   */
  canReactivateSubscription: (state, getters, rootState, rootGetters) => {
    if (!state.subscriptions) {
      return false
    }
    return getters.activeSubscriptions.find(subscription => {
      return subscription.cancelAt !== null
    })
  }
}

const actions = {
  init({ dispatch }) {
    dispatch('getSubscriptions')
  },

  /**
   * Get the subscriptions from the API
   *
   * @param {Object} context
   */
  getSubscriptions({ commit, rootGetters }) {
    const organisationId = rootGetters['employers/organisationId']
    if (!organisationId) {
      return
    }
    return subscriptionsApi.index(organisationId)
      .then(subscriptions => {
        if (!subscriptions) {
          console.log('🎟 No subscription data')
          return
        }
        if (Array.isArray(subscriptions)) {
          console.log('🎟 Legacy subscription')
          if (subscriptions.length === 0) {
            console.log('🎟 No subscriptions for this organisation')
          } else if (subscriptions.length > 0) {
            console.log('🎟 ' + subscriptions.length + ' subscriptions for this organisation')
          }
          commit('subscriptions', subscriptions)
          return
        }
        if (!subscriptions.plan) {
          console.log('🎟 No PAYG subscription')
        }
        console.log('🎟 PAYG subscription')
        commit('subscription', subscriptions)
      })
  },

  /**
   * Create a subscription
   *
   * @param {Object} context
   * @param {Object} payload
   * @return {Promise}
   */
  createSubscription({ commit, rootGetters }, { stripeId, migration, coupon, countryCode, vatNumber }) {
    const organisationId = rootGetters['employers/organisationId']
    return subscriptionsApi.create(organisationId, stripeId, migration, coupon, countryCode, vatNumber)
      .then(data => {
        if (data.action) {
          return data.action
        }
        return {}
      })
  },

  /**
   * Create a subscription option
   *
   * @param {Object} context
   * @param {Object} payload
   * @return {Promise}
   */
  createSubscriptionOption({ commit, rootGetters }, { planSlug, optionRef }) {
    const organisationId = rootGetters['employers/organisationId']
    return subscriptionsApi.createFromOption(organisationId, planSlug, optionRef)
      .then(data => {
        if (data.action) {
          return data.action
        }
        return {}
      })
  },

  /**
   * Create a subscription option
   *
   * @param {Object} context
   * @param {Object} payload
   * @return {Promise}
   */
  confrimSubscriptionOption({ commit, rootGetters }, { optionRef }) {
    const organisationId = rootGetters['employers/organisationId']
    return subscriptionsApi.confrimSubscriptionOption(organisationId, optionRef)
  },

  /**
   * Change a subscription
   *
   * @param {Object} plan
   * @param {string} subscriptionId
   * @param {string} migration
   */
  changeSubscription({ commit, rootGetters }, { stripeId, subscriptionId, migration, coupon, countryCode, vatNumber }) {
    const organisationId = rootGetters['employers/organisationId']
    return subscriptionsApi.change(organisationId, stripeId, subscriptionId, migration, coupon, countryCode, vatNumber)
      .then(data => {
        if (data.action) {
          return data.action
        }
        return {}
      })
  },

  /**
   * Cancel a subscription when it ends
   *
   * @param {string} subscriptionId
   */
  cancelAtPeriodEnd({ commit, rootGetters }, subscriptionId) {
    const organisationId = rootGetters['employers/organisationId']
    return subscriptionsApi.cancelAtPeriodEnd(organisationId, subscriptionId)
      .then(subscriptions => {
        commit('subscriptions', subscriptions)
      })
  },

  /**
   * Cancel a subscription when it ends
   *
   * @param {Object} context
   */
  cancelPaygSubscription({ commit }) {
    return subscriptionsApi.cancelPaygSubscription()
      .then(subscriptions => {
        if (!subscriptions) {
          console.log('🎟 No subscription data')
          // Reset subscription to null if now missing data
          commit('subscription', null)
          return
        }
        console.log('🎟 PAYG subscription')
        commit('subscription', subscriptions)
      })
  },

  /**
   * Upgades a PAYG subscription
   *
   * @param {Object} context
   */
  upgradePaygSubscription({ commit }, upgradePlanSlug) {
    return subscriptionsApi.upgradePaygSubscription(upgradePlanSlug)
      .then(subscriptions => {
        if (!subscriptions) {
          console.log('🎟 No subscription data')
          // Reset subscription to null if now missing data
          commit('subscription', null)
          return
        }
        console.log('🎟 PAYG subscription')
        commit('subscription', subscriptions)
      })
  },

  /**
   * Cancel an existing schedule
   *
   * @param {string} subscriptionId
   */
  releaseSchedule({ commit, rootGetters }, subscriptionId) {
    const organisationId = rootGetters['employers/organisationId']
    return subscriptionsApi.releaseSchedule(organisationId, subscriptionId)
      .then(subscriptions => {
        commit('subscriptions', subscriptions)
      })
  },

  /**
   * Reactivate a subscription
   *
   * @param {string} uuid
   */
  reactivateSubscription({ commit, rootGetters }, uuid) {
    const organisationId = rootGetters['employers/organisationId']
    return subscriptionsApi.reactivate(organisationId, uuid)
      .then(subscriptions => {
        commit('subscriptions', subscriptions)
      })
  }
}

export { state, mutations, getters, actions }

import examsApi from '@api/exams'
import candidateWizardApi from '@api/candidateWizard'
import organisationExamsApi from '@api/organisationExams'

import Vue from 'vue'

const state = {
  exams: null,
  examSuites: null,
  questions: {}
}

const mutations = {
  /**
   * Commits exams
   *
   * @param {Object} state
   * @param {Object}
   */
  store(state, exams) {
    state.exams = exams
  },

  /**
   * Commits custom exams
   *
   * @param {Object} state
   * @param {Object}
   */
  storeCustomExams(state, customExams) {
    if (Array.isArray(state.exams)) {
      state.exams.push(...customExams)
    } else {
      state.exams = customExams
    }
  },

  /**
   * @param {Object} state
   * @param {Object}
   */
  storeExamSuites(state, examSuites) {
    state.examSuites = examSuites
  },

  /**
   * Commits questions
   *
   * @param {Object} state
   * @param {Object}
   */
  questions(state, { examSlug, questions }) {
    Vue.set(state.questions, examSlug, questions)
  }
}

const getters = {
  /**
   * Returns if exams have loaded
   *
   * @param {Object} state
   * @return {string}
   */
  haveExamsLoaded: state => {
    return state.exams !== null
  },

  /**
   * @param {Object} state
   * @return {string}
   */
  haveExamSuitesLoaded: state => {
    return state.examSuites !== null
  },

  /**
   * Returns all exams
   *
   * @param {Object} state
   * @return {array}
   */
  exams: state => {
    if (!state.exams) {
      return []
    }
    let exams
    if (state.exams.libraryExams) {
      const filteredExams = state.exams.libraryExams.filter(exam => {
        return !exam.isHidden
      })

      exams = [...filteredExams, ...state.exams.customExams]
    } else {
      exams = state.exams
    }

    return exams
  },

  /**
   * @param {Object} state
   * @return {array}
   */
  examSuites: state => {
    if (!state.examSuites) {
      return
    }
    return state.examSuites
  },

  /**
   * Exam by exam slug
   *
   * @param {String} examSlug
   * @return {Object}
   */
  examBySlug: (state, getters) => examSlug => {
    if (!getters.haveExamsLoaded) {
      return
    }
    return getters.exams.find(exam => {
      return exam.slug === examSlug
    })
  },

  /**
   * Exam name from the slug
   *
   * @param {String} examSlug
   * @return {Object}
   */
  examNameFromSlug: (state, getters) => examSlug => {
    const exam = getters.examBySlug(examSlug)
    if (!exam) {
      return
    }
    return exam.name
  },

  /**
   * Exam Level from the slug
   *
   * @param {String} examSlug
   * @return {Object}
   */
  examLevelFromSlug: (state, getters) => examSlug => {
    const exam = getters.examBySlug(examSlug)
    if (!exam) {
      return
    }
    return exam.level
  },

  /**
   * Return only standard exams
   *
   * @return {Array}
   */
  standardExams: (state, getters) => {
    if (!state.exams) {
      return
    }
    return getters.exams.filter(exam => {
      return exam.level === 'standard'
    })
  },

  /**
   * Return only foundation exams
   *
   * @return {Array}
   */
  foundationExams: (state, getters) => {
    if (!state.exams) {
      return
    }
    return getters.exams.filter(exam => {
      return exam.level === 'foundation'
    })
  },

  /**
   * Return only all-levels exams
   *
   * @return {Array}
   */
  allLevelsExams: (state, getters) => {
    if (!state.exams) {
      return
    }
    return getters.exams.filter(exam => {
      return !exam.level
    })
  },

  /**
   * Return only example exams
   *
   * @return {Array}
   */
  exampleExams: (state, getters) => {
    if (!state.exams) {
      return
    }
    return getters.exams.filter(exam => {
      return exam.example
    })
  },

  /**
   * Return only standard exams
   *
   * @return {Array}
   */
  examsByJobExamSlugs: (state, getters, rootState, rootGetters) => {
    if (!state.exams) {
      return
    }
    const jobExamSlugs = rootGetters['candidates/jobExamSlugs']

    return getters.exams.filter(exam => jobExamSlugs.includes(exam.slug))
  },

  /**
   * Have we loaded the questions?
   *
   * @param {Object} state
   * @return {array}
   */
  haveQuestionsLoaded: (state) => (examSlug) => {
    return typeof state.questions[examSlug] !== 'undefined'
  },

  /**
   * Exam questions
   *
   * @param {Object} state
   * @return {array}
   */
  questions: (state, getters) => (examSlug) => {
    if (!getters.haveQuestionsLoaded(examSlug)) {
      return
    }
    return state.questions[examSlug]
  }
}

const actions = {
  init({ dispatch }) {
    // dispatch('getExams')
  },

  /**
   * Gets exams and adds to store
   *
   * @param {Object} context
   * @return {Promise}
   */
  getExams({ commit, dispatch, rootGetters }, candidateToken) {
    console.log('📒 Getting exams')
    return examsApi.index(candidateToken)
      .then(exams => {
        console.log('😎 Received exams data')
        commit('store', exams)
      })
  },

  /**
   * Gets custom exams and adds to store
   *
   * @param {Object} context
   * @return {Promise}
   */
  getCustomExams({ commit, getters, rootGetters }) {
    console.log('📒 Getting custom exams')
    return organisationExamsApi.exams(rootGetters['employers/organisationId'])
      .then(exams => {
        console.log('😎 Received custom exams data')
        commit('storeCustomExams', exams)
      })
  },

  /**
   * Stores custom exams from job and adds to store
   *
   * @param {Object} context
   * @return {Promise}
   */
  storeCustomExams({ commit }, customExams) {
    commit('storeCustomExams', customExams)
  },

  /**
   * Gets exams and adds to store
   *
   * @param {Object} context
   * @return {Promise}
   */
  getExamSuites({ commit, dispatch, rootGetters }) {
    console.log('📒 Getting exam suites')
    return examsApi.examSuites()
      .then(examSuites => {
        console.log('😎 Received exam suites data')
        commit('storeExamSuites', examSuites)
        if (rootGetters['employers/organisationId']) {
          dispatch('getCustomExams')
        }
      })
  },

  /**
   * Gets exams and adds to store
   *
   * @param {Object} context
   * @return {Promise}
   */
  getExampleExams({ commit }) {
    console.log('📒 Getting example exams')
    return examsApi.exampleExams()
      .then(exams => {
        console.log('😎 Received example exams data')
        commit('store', exams)
      })
  },

  /**
   * Gets exams and adds to store
   *
   * @param {Object} context
   * @return {Promise}
   */
  getQuestions({ commit }, examSlug) {
    console.log('📒 Getting questions for', examSlug)
    return examsApi.questions(examSlug)
      .then(questions => {
        console.log('😎 Received questions data')
        commit('questions', { examSlug, questions })
      })
  },

  /**
   * Gets questions for custom exam from job and add to store
   *
   * @param {Object} context
   * @return {Promise}
   */
  getCustomExamQuestions({ commit }, { examSlug, examUuid }) {
    console.log('📒 Getting custom questions for', examSlug)
    return candidateWizardApi.questions(examUuid)
      .then(questions => {
        const questionsWithType = questions.map(question => {
          return {
            ...question,
            type: 'choice'
          }
        })
        console.log('😎 Received custom questions data', questionsWithType)
        commit('questions', { examSlug, questions: questionsWithType })
      })
  }
}

export { state, mutations, getters, actions }

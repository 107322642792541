<template>
  <Loader v-bind="$attrs" />
</template>

<script>
import Loader from '@components/Loader'

export default {
  components: {
    Loader
  }
}
</script>

/**
 * https://era86.github.io/2011/11/15/grouping-html-hex-colors-by-hue-in.html
 *
 * @param {string} hex
 * @return {Object}
 */
export const contrastFromHex = hex => {
  let color = rgbFromHex(hex)

  // By default, our Hue and Saturation are 0. Both depend on the Value.
  // In HSV format, the Value is simply the max of the RGB values:
  let max = Math.max(...[color.r, color.g, color.b])
  let min = Math.min(...[color.r, color.g, color.b])

  // Variables for HSV value of hex color
  // (chroma), hue, saturation, value
  let chr = max - min
  let hue = 0
  let val = max
  let sat = 0

  if (val > 0) {
    sat = chr / val
    if (sat > 0) {
      if (color.r === max) {
        hue = 60 * (((color.g - min) - (color.b - min)) / chr)
        if (hue < 0) {
          // Account for wraparound 360 degrees (< 0, + 360)
          hue += 360
        }
      } else if (color.g === max) {
        hue = 120 + 60 * (((color.b - min) - (color.r - min)) / chr)
      } else if (color.b === max) {
        hue = 240 + 60 * (((color.r - min) - (color.g - min)) / chr)
      }
    }
  }

  return {
    hue: parseInt(hue),
    sat: parseInt(sat),
    val: parseInt(val)
  }
}

/**
 * @param {string} hex
 * @return {Object}
 */
export const rgbFromHex = hex => {
  if (!hex.includes('#')) {
    throw new Error('hex code is missing #')
  }
  if (hex.length !== 7) {
    throw new Error('hex code must be in format #xxxxxx')
  }

  // Remove #
  hex = hex.substring(1)

  // We pass 16 to parseInt() to make sure it is parsed as a hex value
  // RGB values are out of 255
  return {
    r: parseInt(hex.substring(0, 2), 16) / 255,
    g: parseInt(hex.substring(2, 4), 16) / 255,
    b: parseInt(hex.substring(4, 6), 16) / 255
  }
}

/**
 * @param {string} hex
 * @return {Boolean}
 */
export const isDark = hex => {
  const rgb = rgbFromHex(hex)
  // Some magic numbers here, but they _should_ work out about right
  return (rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114) > 0.55
}

<template>
  <PageRefine>
    <slot />
  </PageRefine>
</template>

<script>
import PageRefine from '@components/TUI/PageRefine'

export default {
  components: {
    PageRefine
  }
}
</script>

<template>
  <Table :fixed="fixed">
    <slot></slot>
  </Table>
</template>

<script>
import Table from '@components/TUI/Tables/Table'

export default {
  components: {
    Table
  },

  props: {
    fixed: {
      type: Boolean,
      default: false
    }
  }
}
</script>

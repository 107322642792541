<template>
  <component v-bind:is="component"></component>
</template>

<script>
import camelCase from 'lodash/camelCase'
import upperFirst from 'lodash/upperFirst'

export default {
  props: {
    icon: {
      type: String,
      default: 'view-grid'
    }
  },

  computed: {
    /**
     * Icon component
     *
     * @return {string}
     */
    component() {
      return 'ExamIcon'+upperFirst(camelCase(this.icon))
    }
  }
}
</script>

import productsApi from '@api/products'

const state = {
  products: null,
  countryCode: null,
  taxPercentage: null
}

const mutations = {
  /**
   * Commits products
   *
   * @param {Object} state
   * @param {Array} products
   */
  store(state, products) {
    state.products = products
  },

  /**
   * @param {Object} state
   * @param {string} countryCode
   */
  setCountryCode(state, countryCode) {
    state.countryCode = countryCode
  },

  /**
   * @param {Object} state
   * @param {number} taxPercentage
   */
  setTaxPercentage(state, taxPercentage) {
    state.taxPercentage = taxPercentage
  }
}

const getters = {
  /**
   * Returns if products have loaded
   *
   * @param {Object} state
   * @return {string}
   */
  haveProductsLoaded: state => {
    return state.products !== null
  },

  /**
   * Returns products
   *
   * @param {Object} state
   * @return {Array}
   */
  products: state => {
    if (!state.products) {
      return []
    }
    return state.products
      .map(product => {
        if (product.marketingBlurb) {
          product.marketingBlurbHtml = product.marketingBlurb.replace(/(?:\r\n|\r|\n)/g, '<br>')
        }
        return product
      })
  },

  slotProducts: (state, getters) => {
    return getters.products.filter(product => {
      return product.marketingSlot > 0
    })
  },

  /**
   * Returns currently’s set country code
   *
   * @param {Object} state
   * @return {string}
   */
  countryCode: state => {
    return state.countryCode
  },

  /**
   * Returns currently’s set tax percent
   *
   * @param {Object} state
   * @return {number}
   */
  taxPercentage: state => {
    return state.taxPercentage
  }
}

const actions = {
  /**
   * Gets products
   *
   * @param {Object} context
   * @param {string} countryCode
   * @param {string} currencyCode
   * @return {Promise}
   */
  getProducts({ commit }, countryCode, currencyCode) {
    return productsApi.index(countryCode, currencyCode)
      .then(products => {
        // @NB 2021-10-12 products.meta.currency is also avalible if required
        commit('store', products.data)
        commit('setCountryCode', products.meta.countryCode)
        commit('setTaxPercentage', products.meta.taxPercentage)
      })
  }
}

export { state, mutations, getters, actions }

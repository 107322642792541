import routes from './routes'
import Vue from 'vue'
import VueMeta from 'vue-meta'
import VueRouter from 'vue-router'
import store from '@store'

Vue.use(VueRouter)
Vue.use(VueMeta, {
  // The component option name that vue-meta looks for meta info on.
  keyName: 'page'
})

const router = new VueRouter({
  routes,
  mode: 'history',
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { selector: to.hash }
    }
    return { x: 0, y: 0 }
  }
})

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
  // Check if auth is required on this route
  // (including nested routes).
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired)
  const assessmentRoute = routeTo.matched.some((route) => route.meta.assessment)
  const candidateWizardRoute = routeTo.matched.some((route) => route.meta.candidateWizard)

  // Dont fetch an organisation/employer if it's a candidate
  // assessment route
  if (assessmentRoute || candidateWizardRoute) {
    return next()
  }

  // If auth is required and the user is signed in and authorised
  if (store.getters['employers/isAuthorised']) {
    store.dispatch('employers/authorisationAttempted')
    return next()
  }

  // If auth is required and the user has a token sign them in
  // If it fails, send them to sign-in
  if (localStorage.getItem('employer.token')) {
    return store.dispatch('employers/signIn')
      .then(() => {
        store.dispatch('employers/authorisationAttempted')
        return next()
      })
      .catch(() => {
        if (authRequired) {
          store.dispatch('employers/authorisationAttempted')
          return redirectToSignIn()
        }
        return next()
      })
  }

  // If auth isn't required for the route, just continue.
  if (!authRequired) {
    store.dispatch('employers/authorisationAttempted')
    return next()
  }

  redirectToSignIn()

  function redirectToSignIn() {
    // Pass the original route to the sign-in component
    next({ name: 'sign-in', query: { redirectFrom: routeTo.fullPath } })
  }
})

router.beforeResolve(async (routeTo, routeFrom, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              // Complete the redirect.
              next(...args)
              reject(new Error('Redirected'))
            } else {
              resolve()
            }
          })
        } else {
          // Otherwise, continue resolving the route.
          resolve()
        }
      })
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return
  }

  // If we reach this point, continue resolving the route.
  next()
})

export default router

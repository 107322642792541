<template>
  <TBody>
    <tr
      v-for="row in rows"
      :key="`loading-row-${row}`"
    >
      <TBodyTD
        v-for="col in cols"
        :key="`loading-col-${col}`"
      >
        <!-- @TODO Make this do the fancy loading thing? -->
        <div class="bg-gray-100 w-full h-8">
        </div>
      </TBodyTD>
    </tr>
  </TBody>
</template>

<script>
import TBody from '@components/TUI/Tables/TBody'
import TBodyTD from '@components/TUI/Tables/TBodyTD'

export default {
  components: {
    TBody,
    TBodyTD
  },

  props: {
    cols: {
      type: Number,
      default: 3
    },
    rows: {
      type: Number,
      default: 3
    }
  }
}
</script>

<template>
  <div v-if="hasOrganisationLoaded">
    <DiscountBanner
      v-if="notAPublicRoute"
      @bannerActive="discountBannerActivated = $event"
    />
  </div>
</template>

<script>
import DiscountBanner from '@components/DiscountBanner'

import { mapGetters } from 'vuex'

export default {
  components: {
    DiscountBanner
  },

  data() {
    return {
      discountBannerActivated: false
    }
  },

  computed: {
    ...mapGetters({
      hasOrganisationLoaded: 'organisations/hasOrganisationLoaded'
    }),

    notAPublicRoute() {
      const publicRoutes = [
        'sign-in',
        'sign-up',
        'sign-out',
        'magic-link',
        'candidate-sign-in',
        'feedback'
      ]
      return !publicRoutes.includes(this.$route.name)
    }
  },

  watch: {
    discountBannerActivated(banner) {
      if (banner) {
        this.$emit('bannersActivated', true)
      }
    }
  }
}
</script>

<template>
  <div
    v-if="banner"
    class="relative bg-secondary"
  >
    <div class="px-3 py-3 mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div class="pr-16 text-sm sm:text-center sm:px-16">
        <p
          class="font-medium text-white"
        >
          <span
            v-for="(segment, index) in banner.message"
            :key="index"
          >
            <span v-if="segment.type === 'text'">
              {{ segment.data.label }}
            </span>

            <span v-else-if="segment.type === 'discountCode' && !isPAYG">
              <router-link
                v-if="$route.name !== 'settings-plan'"
                :to="{
                  name: 'settings-plan',
                  query: {
                    code: segment.data.code
                  }
                }"
                class="font-bold text-white underline hover:text-gray-300"
              >
                {{ segment.data.label }}
              </router-link>
              <span
                v-else-if="$route.name === 'settings-plan'"
                class="font-bold text-white"
              >
                {{ segment.data.label }}
              </span>
            </span>
            <span v-else-if="segment.type === 'discountCode' && isPAYG">
              <router-link
                v-if="$route.name !== 'settings-payg-plan'"
                :to="{
                  name: 'settings-payg-plan',
                  query: {
                    code: segment.data.code
                  }
                }"
                class="font-bold text-white underline hover:text-gray-300"
              >
                {{ segment.data.label }}
              </router-link>
              <span
                v-else-if="$route.name === 'settings-payg-plan'"
                class="font-bold text-white"
              >
                {{ segment.data.label }}
              </span>
            </span>

            <a
              v-else-if="segment.type === 'link'"
              class="font-bold text-white underline"
              :href="segment.data.link"
            >
              {{ segment.data.label }}
            </a>
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import apiBanners from '@api/banners'

import { isPast, isFuture } from 'date-fns'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      banners: []
    }
  },

  computed: {
    ...mapGetters({
      hasActiveSubscription: 'subscriptions/hasActiveSubscription',
      hasCancelledSubscription: 'subscriptions/hasCancelledSubscription',
      hasOrganisationLoaded: 'organisations/hasOrganisationLoaded',
      haveSubscriptionsLoaded: 'subscriptions/haveSubscriptionsLoaded',
      inTrial: 'organisations/inTrial',
      isPAYG: 'organisations/isPAYG',
      paygSubscription: 'subscriptions/paygSubscription',
      signedUpDaysAgo: 'organisations/signedUpDaysAgo',
      trialDaysRemaining: 'organisations/trialDaysRemaining',
      unlimitedResults: 'organisations/unlimitedResults'
    }),

    /**
     * Return the first banner that matches the rules
     *
     * @return {Object}
     */
    banner() {
      return this.banners.find(banner => {
        // Check for cancelled subscriptions
        if (this.hasCancelledSubscription) {
          // If an organisation has had a plan previously, don’t show
          return false
        }

        // Check start date
        if (banner.startDate) {
          if (isFuture(new Date(banner.startDate))) {
            // Banner hasn’t started yet
            return false
          }
        }

        // Check end date
        if (banner.endDate) {
          if (isPast(new Date(banner.endDate))) {
            // Banner has already ended
            return false
          }
        }

        // Only show to organisations that are still on a trial
        if (banner.trial) {
          if (!this.inTrial) {
            // Trial has finished
            return false
          }
        }

        // Only show to organisations that have completed trial
        if (banner.after_trial) {
          if (this.inTrial) {
            // Trial is ongoing
            return false
          }
        }

        // Only show to organisations that haven’t paid
        if (banner.options.not_paid) {
          if (this.haveSubscriptionsLoaded && this.hasActiveSubscription) {
            // This organisation has already paid
            return false
          }
          if (this.unlimitedResults) {
            // Has unlmited turned on, which is like they’ve paid
            return false
          }
        }
        // Same again, but PAYG
        if (banner.options.not_paid) {
          if (this.paygSubscription) {
            // This organisation has already paid
            return false
          }
        }

        // Only show to organisations that have X days remaining in their trial
        if (banner.options.trial_days_remaining && this.trialDaysRemaining) {
          if (banner.options.trial_days_remaining < this.trialDaysRemaining) {
            return false
          }
        }

        // Only show to organisations that have passed X days
        if (banner.options.created_at_post_days) {
          if (this.signedUpDaysAgo < banner.options.created_at_post_days) {
            // The organisation didn’t sign up long enough ago
            return false
          }
        }

        // Otherwise, we’re good to go!
        return true
      })
    }
  },

  watch: {
    banner: {
      handler(banner) {
        if (banner !== undefined) {
          this.$emit('bannerActive', true)
        } else {
          this.$emit('bannerActive', false)
        }
      },
      immediate: true
    }
  },

  created() {
    apiBanners.banners().then((response) => {
      this.banners = response
    })
  }
}
</script>

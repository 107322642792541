<template>
  <header class="bg-primary z-40">
    <div class="w-full max-w-screen-xl text-left sm:text-center mx-auto flex flex-col justify-center px-4 py-4 h-16">
      <router-link
        v-if="$usesFeature('candidate-wizard')"
        :to="{ name: 'candidate-wizard-dashboard' }"
      >
        <component
          :is="siteLogo"
        />
      </router-link>
      <component
        :is="siteLogo"
        v-else
      />
    </div>
  </header>
</template>

<script>

export default {
  data() {
    return {
      satellite: process.env.VUE_APP_SATELLITE,
      isOpen: false
    }
  },

  computed: {
    siteLogo() {
      return () => import('@components/SiteLogos/' + this.satellite)
    }
  },

  methods: {
    toggle() {
      this.isOpen = !this.isOpen
    },

    beforeEnter(el) {
      el.style.height = '0'
    },
    enter(el) {
      el.style.height = el.scrollHeight + 'px'
    },
    beforeLeave(el) {
      el.style.height = el.scrollHeight + 'px'
    },
    leave(el) {
      el.style.height = '0'
    }
  }
}
</script>

import plansApi from '@api/plans'

const state = {
  plans: null
}

const mutations = {
  /**
   * Commits plans
   *
   * @param {Object} state
   * @param {Object}
   */
  store(state, plans) {
    state.plans = plans
  }
}

const getters = {
  /**
   * Returns if plans have loaded
   *
   * @param {Object} state
   * @return {string}
   */
  havePlansLoaded: state => {
    return state.plans !== null
  },

  /**
   * Returns Assessment plans
   *
   * For legacy reasons this is called `tcPlans`, but should be `assessmentPlans`
   *
   * @param {Object} state
   * @return {string}
   */
  tcPlans: state => {
    if (!state.plans) {
      return
    }
    return state.plans.filter(plan => {
      return plan.includesAssessment
    })
  },

  /**
   * Returns Test Candiates plans
   *
   * @param {Object} state
   * @return {string}
   */
  classicTcPlans: (state, getters) => {
    if (!state.plans) {
      return
    }
    return getters.tcPlans.filter(plan => {
      return plan.classic === true && plan.hidden === false
    })
  },

  /**
   * Returns Test Candiates plans
   *
   * @param {Object} state
   * @return {string}
   */
  currentTcPlans: (state, getters) => {
    if (!state.plans) {
      return
    }
    return getters.tcPlans.filter(plan => {
      return plan.classic === false && plan.hidden === false
    })
  },

  plan: (state, getters) => (slug) => {
    if (!getters.tcPlans) {
      return
    }
    return getters.tcPlans.find(plan => {
      return plan.slug === slug
    })
  }
}

const actions = {
  /**
   * Creates a employer (with employer)
   *
   * @param {Object} context
   * @return {Promise}
   */
  getPlans({ commit }) {
    return plansApi.index()
      .then(plans => {
        commit('store', plans)
        return plans
      })
  }
}

export { state, mutations, getters, actions }
